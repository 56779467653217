<template>
  <div
    :class="$style.MatchesPage"
  >
    <div :class="$style.titleTable">
      <p>дата/место проведения</p>
      <p>команды</p>
      <p>события</p>
    </div>
    <template
      v-if="matches && matches.length"
    >
      <VMatch
        v-for="match in matches"
        :key="match.uuid"
        :match="match"
        @openModal="openModal(match.uuid, match)"
        @openModalSeasons="openModalSeasons(match.uuid)"
        @openModalSeasonsDetail="openModalSeasonsDetail(match.uuid, match)"
      />
    </template>
  </div>
</template>

<script>
import VMatch from '@/components/VMatch.vue';

export default {
  name: 'MatchList',

  components: {
    VMatch,
  },

  props: {
    matches: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showModal: false,
      showModalSeasons: false,
      showModalSeasonsDetail: false,
      uuid: '',
      match: {},
    };
  },

  methods: {
    openModal(key, match) {
      this.uuid = key;
      this.match = match;
      this.showModal = true;
      this.$emit('openModal', this.showModal, this.uuid, this.match);
    },
    openModalSeasons(key) {
      this.uuid = key;
      this.showModalSeasons = true;
      this.$emit('openModalSeasons', this.showModalSeasons, this.uuid);
    },
    openModalSeasonsDetail(key, match) {
      this.uuid = key;
      this.match = match;
      this.showModalSeasonsDetail = true;
      this.$emit('openModalSeasonsDetail', this.showModalSeasonsDetail, this.uuid, this.match);
    },
  },
};
</script>

<style lang="scss" module>
.MatchesPage {
}

.titleTable {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #DEE4EA;

  p {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    color: $base-0;
    @media screen and (max-width: 1189px) {
      display: none;
    }

    &:first-child {
      width: 15%;
    }

    &:nth-child(2) {
      width: 35%;
    }

    &:nth-child(3) {
      width: 3%;
    }

    //&:last-child {
    //  max-width: 240px;
    //}
  }
}
</style>
