<template>
  <div :class="$style.app">
    <VHeader :user="getUser" />
    <main :class="$style.main">
      <router-view></router-view>
    </main>
    <VFooter />
  </div>
</template>

<script>
import VHeader from '@/components/VHeader.vue';
import VFooter from '@/components/VFooter.vue';

export default {
  name: 'App',
  components: {
    VHeader,
    VFooter,
  },
  data() {
    return {};
  },

  computed: {
    getUser() {
      return this.$store.state.user.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  async created() {
    try {
      await this.$store.dispatch('user/getUser');
    } catch (err) {
      if (err.response.status === 401) {
        // this.$router.push('/login');
        sessionStorage.removeItem('user');
      }
    }
  },
};
</script>

<style lang="scss" module>
.main {
  padding: 45px 0 70px;
  min-height: calc(100vh - 425px);
  transition: padding-top 0.3s;
  will-change: padding-top;
}
</style>
