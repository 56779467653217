<template>
  <!-- eslint-disable -->
  <div class="v-input"
       :class="classes"
  >
    <div v-if="label"
         class="v-input__label"
    >
      {{ label }}
    </div>
    <div :class="!label ? 'v-input__inner-no-padding' : 'v-input__inner'">
      <input ref="input"
             v-model="lazyValue"
             v-bind="$attrs"
             class="v-input__native"
             :type="numbers ? 'tel' : type"
             :name="name"
             :disabled="disabled"
             @keydown.enter="onEnter"
             @input="onInput"
             @change="onChange"
             @focus="onFocus"
             @blur="onBlur"
      >

      <div v-if="icon" class="v-input__icon" @click="showPass">
        <IconShowPass/>
      </div>

      <div v-if="preMask"
           class="v-input__premask_wrap"
      >
        <div class="v-input__premask"
             v-html="currentPreMask"
        >
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="errorMsg" class="v-input__error">
        <span class="v-input__error-text">{{ errorMsg }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable */
import IconShowPass from '@/assets/icons/IconShowPass.vue'
import {splitThousands} from '@/assets/js/utils/numbers-utils';
import {
  addMask,
  setCursor,
  getNextCursorPosition,
  setSelection,
} from '@/assets/js/utils/maskUtils';

export default {
  name: 'VInput',

  components: {
    IconShowPass,
  },

  props: {
    name: {
      type: String,
      default: '',
    },

    readOn: {
      type: [Number, String],
      default: '',
    },

    value: {
      type: [String, Number],
      default: '',
    },

    label: {
      type: String,
      default: String,
    },

    type: {
      type: String,
      default: 'text',
    },

    message: {
      type: String,
      default: '',
    },

    /**
     * Устанавливает класс для определения цвета
     */
    color: {
      type: String,
      default: 'default',
    },

    /**
     * Устанавливает класс для определения обратного цвета
     */
    inversed: {
      type: Boolean,
      default: false,
    },

    preMask: {
      type: Boolean,
      default: false,
    },

    mask: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMsg: {
      type: String,
      default: '',
    },

    modClass: {
      type: String,
      default: '',
    },

    icon: {
      type: Boolean,
      default: false,
    },

    presetValue: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      lazyValue: '',
      isFocused: false,
      numbers: false,
      numberInputs: ['phone', 'date', 'time', 'number', 'snils', 'inn', 'pass', 'card', 'code', 'percent', 'year', 'month'],
    };
  },

  computed: {
    classes() {
      return [
        `v-input--${this.color}`,
        this.modClass,
        {
          'is-active': this.lazyValue,
          'is-focused': this.isFocused,
          'is-disabled': this.disabled,
          'is-inversed': this.inversed,
          'has-error': this.error,
          'has-label': this.label,
        },
      ];
    },

    currentMask() {
      switch (this.mask) {
        case 'phone':
          return '+7 ### ###-##-##';
        case 'date':
          return '##.##.####';
        case 'time':
          return '##:##';
        case 'number':
          return '##########';
        case 'snils':
          return '###-###-### ##';
        case 'inn':
          return '############';
        case 'passport':
          return '#### ######';
        case 'card':
          return '#### #### #### ####';
        case 'months':
          return '###';
        case 'fan_card':
          return '### ### ###'
        case 'percent':
        case 'year':
        case 'code':
          return '####';
        default:
          console.log('mask not found');
          return null;
      }
    },

    currentPreMask() {
      if (!this.currentMask) {
        return;
      }

      if (this.lazyValue.length) {
        const regex = new RegExp('^.{0,' + this.lazyValue.length + '}', 'g');
        const pre = this.currentMask.replace(regex, `<span>${this.lazyValue}</span>`);
        return pre.replace(/#/g, '&ensp;');
      }

      return this.currentMask.replace(/#/g, '&ensp;');
    },
  },

  watch: {
    value(val) {
      if (val !== this.lazyValue) {
        let newValue = val;

        if (this.mask && newValue) {
          newValue = addMask(newValue, this.currentMask);
        }
        this.lazyValue = newValue;
        this.$emit('input', newValue);
      }
    },
  },

  created() {
    if (this.presetValue && this.value) {
      this.lazyValue = this.value;
    }

    if (this.mask && this.value) {
      this.lazyValue = addMask(this.value, this.currentMask);
    }

    if (this.numberInputs.includes(this.mask)) {
      this.numbers = true;
    }
  },

  methods: {
    showPass() {
      this.$emit('showPass', this.type);
    },

    onEnter(e) {
      this.$refs.input.blur();
      this.$emit('enter', e);
    },

    onFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);

      if (this.mask === 'phone' && !this.value) {
        this.$nextTick(() => {
          setCursor(e.target, this.value.length);
        });
      }
    },

    onBlur(e) {
      this.isFocused = false;
      this.$emit('blur', e);

      // Automatically remove '+' or '+7' character
      if (this.mask === 'phone' && (this.value === '+' || this.value === '+7')) {
        this.$emit('input', '');
      }
    },

    onChange(e) {
      if (this.numbers) {
        if (
          this.lazyValue.length > 1 && this.lazyValue[0] === '0' ||
          this.lazyValue.length === 1 && this.lazyValue[0] === '-'
        ) {
          // const cleanValue = !this.lazyValue.length || this.lazyValue === '-'
          //   ? ''
          //   : Number(this.lazyValue.split(' ')
          //     .join(''));
          // this.lazyValue = splitThousands(cleanValue
          //   .toString()
          //   .replace(/^(-)?0+(?=\d)/, '$1'));
        }
      }

      this.$emit('change', e);
    },

    onInput(e) {
      if (this.mask) {
        let endPos = e.target.selectionEnd;
        const oldValue = this.lazyValue;
        if (this.mask === 'phone' && this.lazyValue.charAt(0) == 8) {
          this.lazyValue = '+7';
        }

        const newValue = addMask(this.lazyValue, this.currentMask);

        endPos = getNextCursorPosition(endPos, oldValue, newValue, ' ');
        this.lazyValue = newValue;
        this.$nextTick(() => {
          setSelection(e.target, endPos);
        });

        if (this.mask === 'percent') {
          if (this.lazyValue === '00') {
            this.lazyValue = 1;
          }
          this.lazyValue = this.lazyValue > 100 ? '100%' : e.target.value + '%';
        } else if (this.mask === 'months' && this.lazyValue > 360) {
          this.lazyValue = 360;
        }
      } else {
        const value = e.target.value;

        // если установлена максимальная величина строки в пропсе
        if (value.length >= this.maxLength && this.maxLength) {
          this.lazyValue = value.slice(0, this.maxLength);
          this.$emit('input', this.lazyValue);
          return;
        }

        this.lazyValue = value;
      }

      this.$emit('input', this.lazyValue);
    },
  },
};
</script>

<style lang="scss">
.v-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 0 16px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  transition: border-color $default-transition, color $default-transition;

  //@include respond-to(sm) {
  //  min-height: 3.6rem;
  //  padding: 0 1.6rem;
  //  font-size: 1.6rem;
  //}

  /* Colors */
  &--default {
    &:not(.is-inversed) {
      border-color: $base-0;
      color: $base-0;
      caret-color: $base-0;

      @include hover {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.is-focused {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.has-error {
        border-color: $error;

        .v-input__label {
          color: $error;
        }
      }

      &.is-disabled {
        pointer-events: none;
        border-color: rgba($base-0, .1);
        color: rgba($base-0, .2);

        .v-input__label {
          color: rgba($base-0, .2);
        }
      }

      .v-input__label {
        transform: translateY(-20px);
      }
    }

    &.is-inversed {
      border-color: $base-100;
      color: $base-0;
      caret-color: $base-0;

      @include hover {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.is-focused {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.has-error {
        border-color: $error;

        .v-input__label {
          color: $error;
        }
      }

      &.is-disabled {
        pointer-events: none;
        border-color: $base-100;
        color: $base-110;

        .v-input__label {
          color: $base-110;
        }
      }

      .v-input__label {
        color: $base-110;
      }
    }
  }

  /* End colors */

  /* Modificators */

  &.is-active {
    .v-input__premask_wrap {
      opacity: .5;

      span {
        opacity: 0;
      }
    }

    .v-input__placeholder_wrap {
      opacity: .5;

      span {
        opacity: 0;
      }
    }

    .v-input__label {
      //transform: translateY(-1.5rem) scale(.7);
    }
  }

  &.is-focused {
    .v-input__label {
      //transform: translateY(-1.5rem) scale(.7);
    }
  }

  &.has-error {
    .v-input__inner {
      padding-right: 2.4rem;
    }
  }

  &.is-disabled {
    pointer-events: none;
  }

  /* End Modificators */

  &__premask_wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 1.7rem);
    margin-top: 1.7rem;
    font-size: inherit;
    color: red;
    opacity: 0;
    transition: .3s all ease;
  }

  &__placeholder_wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 1.7rem);
    margin-top: 1.7rem;
    font-size: inherit;
    color: $black;
    opacity: 0;
    transition: .3s all ease;
  }

  &__premask {
    display: inline-block;
    line-height: 1.15;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 1.7rem;
  }

  &__inner-no-padding {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__native {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 17px;
    font-weight: inherit;
    color: $base-0;
    box-shadow: none;
    -webkit-appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &__label {
    position: absolute;
    bottom: 50%;
    left: 14px;
    background-color: $white;
    padding: 0 2px;
    font-weight: 400;
    pointer-events: none;
    transform: translateY(50%);
    transform-origin: left center;
    transition: transform .3s ease;
    color: $base-0;
  }

  &__error {
    position: absolute;
    top: 110%;
    left: 0;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $error;
  }

  &__icon {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
    z-index: 50;
  }

}
</style>
