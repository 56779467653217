<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
    <path
      d="M9.00008 17.3334C4.39758 17.3334 0.666748 13.6026 0.666748 9.00008C0.666748 4.39758 4.39758 0.666748 9.00008 0.666748C13.6026 0.666748 17.3334 4.39758 17.3334 9.00008C17.3334 13.6026 13.6026 17.3334 9.00008 17.3334ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9644 13.7141 13.7141C14.9644 12.4639 15.6667 10.7682 15.6667 9.00008C15.6667 7.23197 14.9644 5.53628 13.7141 4.28604C12.4639 3.03579 10.7682 2.33341 9.00008 2.33341C7.23197 2.33341 5.53628 3.03579 4.28604 4.28604C3.03579 5.53628 2.33341 7.23197 2.33341 9.00008C2.33341 10.7682 3.03579 12.4639 4.28604 13.7141C5.53628 14.9644 7.23197 15.6667 9.00008 15.6667ZM9.00008 7.82175L11.3567 5.46425L12.5359 6.64341L10.1784 9.00008L12.5359 11.3567L11.3567 12.5359L9.00008 10.1784L6.64341 12.5359L5.46425 11.3567L7.82175 9.00008L5.46425 6.64341L6.64341 5.46425L9.00008 7.82175Z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDeleteFile',
};
</script>

<style lang="scss" module>
.icon {
  width: 16px;
  height: 16px;
}
</style>
