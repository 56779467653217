/* eslint-disable */
import UserService from "../services/user.service";

export const user = {
  namespaced: true,
  state: {
    user: undefined,
  },
  actions: {
    getUser({ commit }) {
      return UserService.getUserBoard().then(
        (response) => {
          commit("changeUser", response?.data?.data);
          return Promise.resolve(response?.data?.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
    },
    updateUser({ commit }, user) {
      return UserService.updateUserBoard(user).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    changeUser(state, user) {
      state.user = user;
    },
  },
};
