/* eslint-disable */
import AuthService from '../services/auth.service';

const user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user
  ? {status: {loggedIn: true}, user}
  : {status: {loggedIn: false}, user: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({commit}, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({commit}) {
      AuthService.logout();
      commit('logout');
    },

    confirm({commit}, user) {
      return AuthService.confirm(user).then(
        response => {
          commit('confirmSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('confirmFailure');
          return Promise.reject(error);
        },
      )
    },

    reset({commit}, user) {
      return AuthService.reset(user).then(
        response => {
          commit('resetSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('resetFailure');
          return Promise.reject(error);
        },
      )
    },

    link({commit}, user) {
      return AuthService.link(user).then(
        response => {
          commit('linkSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('linkFailure');
          return Promise.reject(error);
        },
      )
    },

    recovery({commit}, user) {
      return AuthService.recovery(user).then(
        response => {
          commit('recoverySuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('recoveryFailure');
          return Promise.reject(error);
        },
      )
    },

    register({commit}, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        },
      );
    },
    proposal({commit}, user) {
      return AuthService.proposal(user).then(
        response => {
          commit('proposalSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('proposalFailure');
          return Promise.reject(error);
        },
      );
    },
    seasons({commit}, user) {
      const formData = new FormData();

      user.links.forEach((value, index) => {
          if(index + 1 <= 10) formData.append(`links[${index}]`, value.link)

          if(index + 1 > 10 && !!value.link) {
            formData.append(`links[${index}]`, value.link)
          }
        }
      );
      formData.append("type_accreditation", user.type_accreditation);
      formData.append("photo", user.photo);
      formData.append("fan_card", user.fan_card);

      return AuthService.seasons(user.uuid, formData).then(
        response => {
          commit('seasonsSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('seasonsFailure');
          return Promise.reject(error);
        },
      );
    },

    updateSeasonsRequest({commit}, user) {
      const formData = new FormData();

      user.links.forEach((value, index) => {
          if(index + 1 <= 10) formData.append(`links[${index}]`, value.link)

          if(index + 1 > 10 && !!value.link) {
            formData.append(`links[${index}]`, value.link)
          }
        }
      );
      formData.append("type_accreditation", user.type_accreditation);

      return AuthService.updateSeasonsRequest(user.uuid, formData).then(
        response => {
          commit('seasonsSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('seasonsFailure');
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    confirmSuccess(state) {
      state.state = state;
    },
    confirmFailure(state) {
      state.state = state;
    },
    resetSuccess(state) {
      state.state = state;
    },
    resetFailure(state) {
      state.state = state;
    },
    linkSuccess(state) {
      state.state = state;
    },
    linkFailure(state) {
      state.state = state;
    },
    recoverySuccess(state) {
      state.state = state;
    },
    recoveryFailure(state) {
      state.state = state;
    },
    proposalSuccess(state) {
      state.state = state;
    },
    proposalFailure(state) {
      state.state = state;
    },
    seasonsSuccess(state) {
      state.state = state;
    },
    seasonsFailure(state) {
      state.state = state;
    },
  },
};
