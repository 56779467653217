<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
    <circle cx="11" cy="11.5" r="2.75" stroke="#0959A1" stroke-width="1.5"/>
    <path d="M20.5 11.5C18.6658 7.92524 15.0993 5.5 11 5.5C6.90074 5.5 3.33417 7.92524 1.5 11.5" stroke="#0959A1"
          stroke-width="1.5" stroke-linecap="round"/>
  </svg>


</template>

<script>
export default {
  name: 'IconShowPass',
};
</script>

<style lang="scss" module>
.icon {
  width: 22px;
  height: 20px;
}
</style>
