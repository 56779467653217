/* eslint-disable */
// необходим и для плиток с названиями, поэтому выносим в скоуп внешнего модуля чтобы избежать дублирования
// создаем map для работы с форматами
// Внимание для расширения валидации по другим форматам, дописываем сюда новые форматы и их значения для файллоадера

const patternTypesFiles = new Map([
  ['pdf', 'application/pdf'],
  ['png', 'image/png'],
  ['jpg', 'image/jpg'],
  ['jpeg', 'image/jpeg'],
  ['tiff', 'image/tiff'],
  ['xls', 'application/vnd.ms-excel'],
  ['doc', 'application/msword'],
  ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  ['ppt', 'application/vnd.ms-powerpoint'],
  ['pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
]);

// Эта функция нужна для валидации типов файлов в файлоадере и не только (велосипед)
// Принимаем на вход массив с типами файлов и сам файл, запускаем валидацию по формату
export function fileTypeValidation(listTypesFile, file) {
  return listTypesFile.some(format => patternTypesFiles.get(format.toLowerCase()) === file.type);
}
