import VButton from '@/components/ui/VButton.vue';
import VInput from '@/components/ui/VInput.vue';
import VCheckbox from '@/components/ui/VCheckbox.vue';
import VFileLoader from '@/components/ui/VFileLoader.vue';
import VSelect from '@/components/ui/VSelect.vue';
import ImageLazy from '@/components/ui/ImageLazy.vue';
import VModal from '@/components/ui/VModal.vue';
import VRadio from '@/components/ui/VRadio.vue';

export default [
  VButton,
  VInput,
  VCheckbox,
  VFileLoader,
  VSelect,
  ImageLazy,
  VModal,
  VRadio,
];
