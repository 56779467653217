<template>
  <div
    class="container"
    :class="$style.MatchesPage"
  >
    <!--    <Multiselect-->
    <!--      label="name"-->
    <!--      :object="true"-->
    <!--      placeholder="Команда"-->
    <!--      :class="$style.select"-->
    <!--      v-model="team"-->
    <!--      :searchable="true"-->
    <!--      :options="createOptions"-->
    <!--      ref="multiselect"-->
    <!--      @select="loadOtherTeamMatch"-->
    <!--      @clear="asyncData"-->
    <!--    />-->
    <transition name="fade-content"
                mode="out-in"
    >
      <MatchesList
        :matches="matches"
        @openModal="openModal"
        @openModalSeasons="openModalSeasons"
        @openModalSeasonsDetail="openModalSeasonsDetail"
      />
    </transition>

    <div :class="$style.buttonWrapper">
      <VButton
        v-if="pageInfo.currentPage < pageInfo.lastPage"
        color="primary"
        responsive
        :disabled="isLoading"
        @click="loadMatch"
      >
        показать ещё
      </VButton>
    </div>
  </div>
  <transition name="modal">
    <VModal v-if="showModal" @close="showModal = false" @keypress.esc="showModal = false">
      <ProposalForm
        :user-load="getUser"
        :uuid="uuid"
        :match="match"
        @close="showModal = false"
        @isRequest="updateMatches"
      />
    </VModal>
  </transition>
  <transition name="modal">
    <VModal
      v-if="showModalSeasons"
      @close="showModalSeasons = false"
      @keypress.esc="showModalSeasons = false"
    >
      <ProposalSeasonsForm
        :user-load="getUser"
        :uuid="uuid"
        @close="showModalSeasons = false"
        @isRequest="updateMatches"
      />
    </VModal>
  </transition>
  <transition name="modal">
    <VModal
      v-if="showModalSeasonsDetail"
      @close="showModalSeasonsDetail = false"
      @keypress.esc="showModalSeasonsDetail = false"
    >
      <DetailSeasonsForm
        :user-load="getUser"
        :uuid="uuid"
        :status="match && match.status"
        @close="showModalSeasonsDetail = false"
        @isRequest="updateMatches"
      />
    </VModal>
  </transition>
</template>

<script>
import { lockBody, unlockBody } from '@/assets/js/utils/commonUtils';
// import Multiselect from '@vueform/multiselect';
import authHeader from '@/services/auth-header';
import MatchesList from '@/components/MatchesList.vue';
import ProposalForm from '@/components/ProposalForm.vue';
import ProposalSeasonsForm from '@/components/ProposalSeasonsForm.vue';
import DetailSeasonsForm from '@/components/DetailSeasonsForm.vue';
import UserService from '@/services/user.service';
import axios from 'axios';

export default {
  name: 'UserPage',

  components: {
    ProposalForm,
    MatchesList,
    ProposalSeasonsForm,
    DetailSeasonsForm,
    // Multiselect,
  },

  data() {
    return {
      showModal: false,
      showModalSeasons: false,
      showModalSeasonsDetail: false,
      isRequest: false,
      matches: [],
      team: {},
      user: {},
      teams: [],
      isLoading: false,
      uuid: '',
      status: '',
      seasons: [],
      pageInfo: {
        page: null,
        limit: null,
        currentPage: null,
        lastPage: null,
      },
      match: {},
      data: [
        {
          uuid: '0afa9367-656a-4139-b53b-e4289f45fe7b',
          status: {
            slug: 'open',
            name: 'Открыт',
          },
          has_request: false,
        },
      ],
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    getUser() {
      return this.user.data;
    },

    createOptions() {
      return this.teams.map((el) => ({
        ...el,
        uuid: el.uuid,
        value: el.name,
        name: el.name,
        logo: el.logo,
      }));
    },
  },

  async created() {
    try {
      await this.$store.dispatch('user/getUser');

      if (!this.loggedIn) {
        this.$router.push('/login');
      }
    } catch (err) {
      if (err.response.status === 401) {
        this.$router.push('/login');
        sessionStorage.removeItem('user');
      }
    }
  },

  mounted() {
    this.asyncData();

    UserService.getUserBoard().then(
      (response) => {
        this.user = response.data;
      },
      (error) => {
        if (error.response.status === 401) {
          this.$router.push('/login');
          sessionStorage.removeItem('user');
        }
        // eslint-disable-next-line
        this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      },
    );
  },
  watch: {
    showModal() {
      if (this.showModal) {
        lockBody();
      } else {
        unlockBody();
      }
    },

    showModalSeasons() {
      if (this.showModalSeasons) {
        lockBody();
      } else {
        unlockBody();
      }
    },

    showModalSeasonsDetail() {
      if (this.showModalSeasonsDetail) {
        lockBody();
      } else {
        unlockBody();
      }
    },

    isRequest() {
      if (this.isRequest) {
        this.asyncData();
      }
    },
  },

  methods: {
    openModal(val, key, match) {
      this.uuid = key;
      this.showModal = val;
      this.match = match;
    },

    openModalSeasons(val, key, match) {
      this.uuid = key;
      this.showModalSeasons = val;
      this.match = match;
    },

    openModalSeasonsDetail(val, key, match) {
      this.uuid = key;
      this.showModalSeasonsDetail = val;
      this.match = match;

      console.log(match);
    },

    updateMatches(val) {
      this.isRequest = val;
    },

    async asyncData() {
      const matches = await axios.get(`${process.env.VUE_APP_API_URL}/matches`, { headers: authHeader() });
      const teams = await axios.get(`${process.env.VUE_APP_API_URL}/teams`, { headers: authHeader() });
      const seasons = await axios.get(`${process.env.VUE_APP_API_URL}/accreditation/seasons`, { headers: authHeader() });
      this.matches = matches.data.data;
      this.seasons = seasons.data.data;
      this.pageInfo = { ...matches.data.meta };
      this.teams = teams.data.data;
      this.matches = this.matches.concat(this.seasons);
    },

    async loadMatch() {
      try {
        this.isLoading = true;
        this.pageInfo.currentPage += 1;

        const matches = await this.fetchMatches();

        if (matches?.data?.data?.length) {
          this.matches = this.matches.concat(matches.data.data);
        }

        this.$nextTick(() => {
          this.isLoading = false;
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('[realtyFilter/appendMatches] request failed: ', error);

        if (error.response.status === 401) {
          this.$router.push('/login');
          sessionStorage.removeItem('user');
        }

        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },

    fetchMatches() {
      const api = this.team?.uuid
        ? `matches?per_page=5&page=${this.pageInfo.currentPage}&team=${this.team.uuid}`
        : `matches?per_page=5&page=${this.pageInfo.currentPage}`;

      return axios.get(`${process.env.VUE_APP_API_URL}/${api}`, { headers: authHeader() });
    },
    async loadOtherTeamMatch() {
      this.pageInfo.currentPage = 1;
      const matches = await axios.get(`${process.env.VUE_APP_API_URL}/matches?per_page=5&page=${this.pageInfo.currentPage}&team=${this.team.uuid}`, { headers: authHeader() });
      this.matches = matches.data.data;
      this.pageInfo = { ...matches.data.meta };
    },
  },
};
</script>

<style lang="scss" module>
.MatchesPage {

}

.select {
  margin-left: 0;
  margin-bottom: 50px;
  border: 0;
  width: 100%;
  max-width: 700px;
  min-height: 50px;

  &:global(.multiselect-single-label) {
    font-weight: 500;
    font-size: 26px;
    color: $base-0;
  }

  &:global(.multiselect) {
    border-bottom: 1px solid $border-color;
  }
}

.buttonWrapper {
  width: 100%;
  max-width: 250px;
  margin: 30px auto;
}
</style>
