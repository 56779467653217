<template>
  <div
    :class="$style.registrationPage"
  >
    <RegistrationForm/>
  </div>
</template>

<script>
import RegistrationForm from '@/components/RegistrationForm.vue';

export default {
  name: 'RegistrationPage',
  components: {
    RegistrationForm,
  },
};
</script>

<style lang="scss" module>
.registrationPage {

}
</style>
