/* eslint-disable */

function isElement(o) {
  return typeof HTMLElement === 'object'
    ? o instanceof HTMLElement
    : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string';
}

export function getFontSize() {
  const html = window.document.documentElement;
  const style = window.getComputedStyle(html, null).getPropertyValue('font-size');
  return parseFloat(style);
}

export function lockBody() {

  const paddingWidth = window.innerWidth - document.body.offsetWidth;

  document.documentElement.style.height = '100%';
  document.body.style.height = '100%';
  document.body.style.overflow = 'hidden';
  document.body.style.paddingRight = `${paddingWidth}px`;
}

export function unlockBody() {

  document.documentElement.style.height = null;
  document.body.style.height = null;
  document.body.style.overflow = null;
  document.body.style.paddingRight = null;
}

export function convertToObject(data) {
  const result = {};
  data.forEach((item) => {
    if (item.choices) {
      result[item.name] = [...item.choices];
    }
    if (item.range) {
      result[item.name] = { ...item.range };
    }
    if (item.ranges) {
      result[item.name] = { ...item.ranges };
    }
  });

  return result;
}

export function scrollTo(id = false, offset = 0, force = false) {
  if (!id) {
    window.scroll({ top: 0, left: 0, behavior: force ? 'instant' : 'smooth' });
  } else {
    const target = document.getElementById(id);
    if (target) {
      const position = target.getBoundingClientRect().top + window.pageYOffset;
      window.scroll({
        top: position - offset,
        left: 0,
        behavior: force ? 'instant' : 'smooth',
      });
    }
  }
}

export function monthByNumber(num, type = 'full') {
  if (typeof num !== 'number' || isNaN(num) || num < 0 || num > 11) {
    console.warn('[monthByNumber] Wrong number ', num);
    return '';
  }
  const months = {
    0: {
      full: 'Январь',
      short: 'Янв',
      case: 'Января',
    },
    1: {
      full: 'Февраль',
      short: 'Фев',
      case: 'Февраля',
    },
    2: {
      full: 'Март',
      short: 'Мар',
      case: 'Марта',
    },
    3: {
      full: 'Апрель',
      short: 'Апр',
      case: 'Апреля',
    },
    4: {
      full: 'Май',
      short: 'Май',
      case: 'Мая',
    },
    5: {
      full: 'Июнь',
      short: 'Июн',
      case: 'Июня',
    },
    6: {
      full: 'Июль',
      short: 'Июл',
      case: 'Июля',
    },
    7: {
      full: 'Август',
      short: 'Авг',
      case: 'Августа',
    },
    8: {
      full: 'Сентябрь',
      short: 'Сен',
      case: 'Сентября',
    },
    9: {
      full: 'Октябрь',
      short: 'Окт',
      case: 'Октября',
    },
    10: {
      full: 'Ноябрь',
      short: 'Ноя',
      case: 'Ноября',
    },
    11: {
      full: 'Декабрь',
      short: 'Дек',
      case: 'Декабря',
    },
  };

  return months[num][type];
}

export function dayByNumber(num, type = 'full') {
  if (num > 6) {
    console.warn('[dayByNumber] Wrong number,', num);
    return '';
  }

  const days = {
    0: {
      full: 'Воскресенье',
      short: 'вс',
    },
    1: {
      full: 'Понедельник',
      short: 'пн',
    },
    2: {
      full: 'Вторник',
      short: 'вт',
    },
    3: {
      full: 'Среда',
      short: 'ср',
    },
    4: {
      full: 'Четверг',
      short: 'чт',
    },
    5: {
      full: 'Пятница',
      short: 'пт',
    },
    6: {
      full: 'Суббота',
      short: 'сб',
    },
  };

  return days[num][type];
}

export function prepositionalCase(string) {
  if (typeof string !== 'string' || !string) {
    console.warn('[prepositionalCase]: argument string is not a string or empty');
    return '';
  }

  const doubleEndings = [
    ['ия', 'ии'],
    ['ия', 'ии'],
    ['ий', 'ом'],
    ['ое', 'ом'],
    ['ая', 'ой'],
    ['ль', 'ле'],
  ];

  const singleEndings = [
    ['а', 'е'],
    ['о', 'е'],
    ['и', 'ах'],
    ['ы', 'ах'],
    ['ь', 'и'],
  ];

  const cityDoubleEnding = string.substring(string.length - 2, string.length);
  const citySingleEnding = string.substring(string.length - 1, string.length);

  const singleEnding = singleEndings.find((en) => en[0] === citySingleEnding);
  let doubleEnding = null;

  if (!singleEnding) {
    doubleEnding = doubleEndings.find((en) => en[0] === cityDoubleEnding);
  }

  if (!doubleEnding && !singleEnding) {
    return `${string}e`;
  } if (doubleEnding) {
    return string.substring(0, string.length - 2) + doubleEnding[1];
  }

  return string.substring(0, string.length - 1) + singleEnding[1];
}

export function cleanPhone(prettyPhoneNumber) {
  return prettyPhoneNumber.replace(/ |-|\(|\)|_/g, '');
}

export function stringToObject(str, separator = ',') {
  if (!str) {
    return [];
  }

  return str.split(separator);
}

export function addGradientWaveAnimation(element, colors) {
  if (!element) {
    console.warn('[utils/addGradientWaveAnimation]: Empty DOM element');
    return;
  }

  if (!isElement(element)) {
    console.warn('[utils/addGradientWaveAnimation]: Element should be DOM element');
    return;
  }

  if (!colors) {
    console.warn('[utils/addGradientWaveAnimation]: Empty color set');
    return;
  }

  if (!Array.isArray(colors)) {
    console.warn(`[utils/addGradientWaveAnimation]: Got "${typeof colors}" expected "array"`);
    return;
  }

  let step = 0;
  const colorIndices = [0, 1, 2, 3];
  const gradientSpeed = 0.020;

  function updateGradient() {
    const [
      c0_0,
      c0_1,
      c1_0,
      c1_1,
    ] = [
      colors[colorIndices[0]],
      colors[colorIndices[1]],
      colors[colorIndices[2]],
      colors[colorIndices[3]],
    ];

    const istep = 1 - step;

    const [r1, g1, b1] = [
      Math.round(istep * c0_0[0] + step * c0_1[0]),
      Math.round(istep * c0_0[1] + step * c0_1[1]),
      Math.round(istep * c0_0[2] + step * c0_1[2]),
    ];
    const color1 = `rgba(${r1}, ${g1}, ${b1}, 1)`;

    const [r2, g2, b2] = [
      Math.round(istep * c1_0[0] + step * c1_1[0]),
      Math.round(istep * c1_0[1] + step * c1_1[1]),
      Math.round(istep * c1_0[2] + step * c1_1[2]),
    ];
    const color2 = `rgba(${r2}, ${g2}, ${b2}, 1)`;

    const backgroundGradient = `linear-gradient(180deg, ${color1} 0%, ${color2} 100%)`;

    element.style.background = backgroundGradient;

    step += gradientSpeed;

    if (step >= 1) {
      step %= 1;
      // eslint-disable-next-line prefer-destructuring
      colorIndices[0] = colorIndices[1];
      // eslint-disable-next-line prefer-destructuring
      colorIndices[2] = colorIndices[3];

      // eslint-disable-next-line max-len
      colorIndices[1] = (colorIndices[1] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
      // eslint-disable-next-line max-len
      colorIndices[3] = (colorIndices[3] + Math.floor(1 + Math.random() * (colors.length - 1))) % colors.length;
    }
  }

  window.gradientCount = window.gradientCount ? window.gradientCount + 1 : 1;

  window[`gradient-${window.gradientCount}`] = setInterval(updateGradient, 150);

  return window[`gradient-${window.gradientCount}`];
}

export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    // eslint-disable-next-line
    const context = this;
    const args = arguments;

    function later() {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    }

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}
