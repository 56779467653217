<template>
  <!--eslint-disable-->
  <div :class="$style.RegistrationForm">
    <h3 :class="$style.title">Регистрация прессы</h3>
    <form :class="$style.form" @submit.prevent="submitForm(user)">
      <div :class="$style.fieldset">
        <div
          :class="[
            $style.inputWrapper,
            $style._row,
            {
              [$style._error]: Boolean(errors.surname) || Boolean(errors.name),
            },
          ]"
        >
          <VInput
            v-model="user.surname"
            name="surname"
            label="Фамилия*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.surname"
            :error="Boolean(errors.surname)"
            @input="errors.surname = ''"
          />
          <VInput
            v-model="user.name"
            name="name"
            label="Имя*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.name"
            :error="Boolean(errors.name)"
            @input="errors.name = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.patronymic"
            name="surname"
            label="Отчество"
            :disabled="isLoading"
            show-label
            :error-msg="errors.patronymic"
            :error="Boolean(errors.patronymic)"
            @input="errors.patronymic = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.birth_date"
            name="birth_date"
            mask="date"
            placeholder="ДД/ММ/ГГГГ"
            label="Дата рождения*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.birth_date"
            :error="Boolean(errors.birth_date)"
            @input="errors.birth_date = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <template v-if="!isHideSelect">
            <div :class="$style.labelSelect">
              <span>Издание*</span>
            </div>
            <Multiselect
              name="edition_id"
              label="title"
              :object="true"
              :class="$style.select"
              v-model="edition_id"
              :searchable="true"
              :options="createOptions"
              ref="multiselect"
            />
          </template>
          <template v-if="isHideSelect">
            <VInput
              v-model="edition"
              name="edition"
              label="Издание*"
              :disabled="isLoading"
              show-label
              :error-msg="errors.edition"
              :error="Boolean(errors.edition)"
              @input="errors.edition = ''"
            />
          </template>
          <div :class="$style._mt10">
            <VCheckbox value="asas" @change="isShownSelect">
              Моего издания нет в списке
            </VCheckbox>
          </div>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.fan_card"
            name="fan_card"
            mask="fan_card"
            label="Номер карты болельщика"
            :disabled="isLoading"
            show-label
            :error-msg="errors.fan_card"
            :error="Boolean(errors.fan_card)"
            @input="errors.fan_card = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.fan_card) }">
            Нет карты болельщика?
            <a href="https://fcdm.ru/fanid">Узнайте как получить ее</a>
          </p>
        </div>
        <div
          :class="[
            $style.inputWrapper,
            $style._row,
            {
              [$style._error]:
                Boolean(errors.password) ||
                Boolean(errors.password_confirmation),
            },
          ]"
        >
          <VInput
            v-model="user.password"
            name="password"
            :type="showPass"
            label="Пароль*"
            icon
            :disabled="isLoading"
            show-label
            :error-msg="errors.password"
            :error="Boolean(errors.password)"
            @input="errors.password = ''"
            @showPass="showHidePass"
          />
          <VInput
            v-model="user.password_confirmation"
            name="password_confirmation"
            label="Повторите пароль*"
            :type="showPass"
            icon
            :disabled="isLoading"
            show-label
            :error-msg="errors.password_confirmation"
            :error="Boolean(errors.password_confirmation)"
            @input="errors.password_confirmation = ''"
            @showPass="showHidePass"
          />
        </div>
      </div>
      <div :class="$style.fieldset">
        <div :class="[$style.inputWrapper]">
          <VInput
            v-model="user.phone"
            name="phone"
            mask="phone"
            label="Номер телефона*"
            :disabled="isLoading"
            show-label
            type="tel"
            :error-msg="errors.phone"
            :error="Boolean(errors.phone)"
            @input="errors.phone = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.phone) }">
            Должен соответствовать номеру телефона, указанному в госуслугах
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.contact_phone"
            name="contact_phone"
            mask="phone"
            type="tel"
            label="Контактный номер"
            :disabled="isLoading"
            show-label
            :error-msg="errors.contact_phone"
            :error="Boolean(errors.contact_phone)"
            @input="errors.contact_phone = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.contact_phone) }">
            Предпочитаемый вид связи
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.email"
            name="email"
            label="E-mail*"
            :disabled="isLoading"
            show-label
            :error-msg="errors.email"
            :error="Boolean(errors.email)"
            @input="errors.email = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.email) }">
            На данную почту будут приходить уведомления о результатах
            рассмотрения заявок
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VFileLoader
            :class="$style.input"
            @change="getFile"
            name="photo"
            label="Фото*"
            :file-formats="['png', 'jpeg']"
            @input="errors.photo = ''"
            :error-msg="errors.photo"
            :error="Boolean(errors.photo)"
          />
          <p :class="$style.info">Формат 3х4, jpg/png, размер не более 5Мб, на белом фоне</p>

          <div :class="$style._mt18">
            <VCheckbox
              :value="user.has_informational_messages"
              v-model="informational_messages"
              name="has_informational_messages"
              @input="informationMessage"
            >
              Хочу получать информационные сообщения и рассылки от ФК «Динамо
              Москва»
            </VCheckbox>
          </div>
          <div :class="$style._mt18">
            <VCheckbox
              :value="user.has_privacy_policy"
              v-model="policy"
              name="has_privacy_policy"
              @change="checkPolicy"
              :error-msg="errors.has_privacy_policy"
              :error="Boolean(errors.has_privacy_policy)"
            >
              Согласие с условиями
              <a
                style="text-decoration: underline"
                href="https://fcdm.ru/club/policy"
                >«Политики конфиденциальности»</a
              >
            </VCheckbox>
          </div>
          <VButton
            color="primary"
            type="submit"
            responsive
            :disabled="isLoading"
          >
            Зарегистрироваться
          </VButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line
import Multiselect from "@vueform/multiselect";
import axios from 'axios';

// const regexName = /^[-\sa-zA-Zа-яА-ЯЁё]+$/;
const regexPhone = /^.{12}$/;
const regexPass = /(?=.{6,})/;
const regexEmail = /(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}\b/;
const regexDate = /(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*\b/;

export default {
  name: 'RegistrationForm',

  components: {
    Multiselect,
  },

  data() {
    return {
      isHideSelect: false,
      showPass: 'password',
      informational_messages: false,
      policy: false,
      value: null,
      message: '',
      edition: '',
      edition_id: {},
      options: [],
      isFormEnable: null,
      user: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        contact_phone: '',
        email: '',
        birth_date: '',
        fan_card: '',
        password: '',
        password_confirmation: '',
        photo: null,
        has_informational_messages: '0',
        has_privacy_policy: false,
      },
      isLoading: false,
      requiredFields: {
        // name: this.user.name,
        // surname: this.user.surname,
        // birth_date: '',
        // edition_id: '',
      },

      errors: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        contact_phone: '',
        email: '',
        birth_date: '',
        edition_id: '',
        edition: '',
        password: '',
        password_confirmation: '',
        photo: '',
        has_informational_messages: '',
        has_privacy_policy: '',
      },
    };
  },

  computed: {
    createOptions() {
      return this.options
        .map((el) => ({
          ...el,
          id: el.id,
          value: el.title,
          label: el.title,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },

    setPropertyNameEdition() {
      return this.isHideSelect
        ? { edition: this.edition }
        : { edition_id: this.edition_id.id };
    },
  },
  created() {
    this.asyncData();
  },
  watch: {
    user: {
      handler(newValue) {
        if (newValue.fan_card.length === 12) {
          this.user.fan_card = newValue.fan_card.slice(0, 11);
        }
      },
      deep: true,
    },
  },
  methods: {
    isShownSelect(val) {
      this.isHideSelect = val;
    },

    showHidePass(value) {
      if (value === 'password') {
        this.showPass = 'text';
      } else {
        this.showPass = 'password';
      }
    },

    getFile(value) {
      if (value === null || ('File' in window && value instanceof File)) {
        this.user.photo = value;
      }
    },

    async asyncData() {
      const options = await axios.get(
        `${process.env.VUE_APP_API_URL}/references/editions`,
      );
      this.options = [...options.data.data];
    },
    informationMessage() {
      this.informational_messages = !this.informational_messages;

      if (this.user.has_informational_messages === '0') {
        this.user.has_informational_messages = '1';
      } else {
        this.user.has_informational_messages = '0';
      }
    },

    submitForm(user) {
      // console.log('user', user);
      // console.log('user data', this.user);

      // this.checkEmail();
      // this.checkPhone();
      // this.checkDate();
      // this.checkPassword();
      // this.checkForm();
      this.handleRegister(user);

      // if (this.isFormEnable) {
      //   this.handleRegister(user);
      // }
    },

    checkForm() {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line
      Object.values(this.errors).every((x) => !x)
        ? (this.isFormEnable = true)
        : (this.isFormEnable = false);
    },

    checkPolicy() {
      this.policy = !this.policy;
      this.user.has_privacy_policy = this.policy;
      if (this.errors.has_privacy_policy) {
        this.errors.has_privacy_policy = '';
      }
    },
    handleRegister(user) {
      this.isLoading = true;

      this.$store
        .dispatch('auth/register', { ...user, ...this.setPropertyNameEdition })
        .then(
          () => {
            this.$router.push('/confirm');
          },
          (error) => {
            this.isLoading = false;
            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(
                ([key, value]) => {
                  // eslint-disable-next-line prefer-destructuring
                  this.errors[key] = value[0];
                },
              );
            }
            this.message = error.response.data.message ?? '';
          },
        );
    },

    validValue(value, regex) {
      return regex.test(value);
    },

    checkTextField(field) {
      this.errors[field] = '';
      if (!this.requiredFields[field]) {
        this.errors[field] = 'Это поле является обязательным';
      } else if (!this.requiredFields[field].trim().length) {
        this.errors[field] = 'Поле не может быть пустым';
      }
    },

    checkPhone() {
      this.errors.phone = '';
      if (!this.user.phone) {
        this.errors.phone = 'Это поле является обязательным';
      } else if (
        !this.validValue(this.user.phone.replace(/[()\s-]/g, ''), regexPhone)
      ) {
        this.errors.phone = 'Телефон состоит из 11 цифр';
      }
    },

    checkDate() {
      this.errors.birth_date = '';
      if (!this.user.birth_date) {
        this.errors.birth_date = 'Это поле является обязательным';
      } else if (
        !this.validValue(
          this.user.birth_date.replace(/[()\s-]/g, ''),
          regexDate,
        )
      ) {
        this.errors.birth_date = 'Проверьте корректность данных';
      }
    },

    checkPassword() {
      this.errors.password = '';
      if (!this.user.password) {
        this.errors.password = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.password, regexPass)) {
        this.errors.password = 'Пароль должен состоять из 6 символов и более';
      } else if (this.user.password !== this.user.password_confirmation) {
        this.errors.password = 'Пароли не совпадают';
      }
    },

    checkEmail() {
      this.errors.email = '';
      if (!this.user.email) {
        this.errors.email = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.email, regexEmail)) {
        this.errors.email = 'Заполните поле корректно';
      }
    },
  },
};
</script>
<style lang="scss" module>
.RegistrationForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  .form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 805px;

    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: block;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  font-feature-settings: "case";

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.fieldset {
  width: calc(50% - 52px);
  margin-top: 40px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.inputWrapper {
  width: 100%;
  max-width: 365px;
  margin: 0 auto 35px;

  //&:not(:last-child) {
  //  margin-bottom: 35px;
  //}

  &._error {
    margin-bottom: 70px;
  }

  p {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: $base-0;
    margin-top: 8px;

    &._error {
      margin-top: 35px;
    }

    a {
      text-decoration: underline;
    }
  }

  .labelSelect {
    position: relative;

    span {
      z-index: 20;
      font-size: 14px;
      position: absolute;
      bottom: 50%;
      left: 14px;
      background-color: $white;
      padding: 0 2px;
      font-weight: 400;
      pointer-events: none;
      transform-origin: left center;
      transition: transform 0.3s ease;
      color: $base-0;
      transform: translateY(10px) scale(0.7);
    }
  }

  &._row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    :global(.v-input) {
      width: calc(50% - 8px);
    }
  }

  ._mt18 {
    margin-top: 18px;
  }

  ._mt10 {
    margin-top: 10px;
  }

  .select {
    min-height: 50px;
    border-color: $base-0;
    &:global(.is-active) {
      box-shadow: none;
    }

    :global(.multiselect-option.is-selected) {
      background: $base-0;
    }
  }

  .info {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
  }
}
</style>
