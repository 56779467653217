<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
    <circle cx="11.5" cy="6.5" r="6.5" fill="white"/>
    <path
      d="M5.32258 13.8359C2.383 13.8359 0 16.2811 0 19.2974V21.028C0 22.125 0.774802 23.0604 1.82993 23.2371C7.90311 24.2545 14.0969 24.2545 20.1701 23.2371C21.2252 23.0604 22 22.125 22 21.028V19.2974C22 16.2811 19.617 13.8359 16.6774 13.8359H16.1936C15.9317 13.8359 15.6715 13.8784 15.4226 13.9618L14.1941 14.3734C12.1186 15.0688 9.88137 15.0688 7.80586 14.3734L6.57739 13.9618C6.32847 13.8784 6.06825 13.8359 5.80639 13.8359H5.32258Z"
      fill="white"/>
  </svg>

</template>

<script>
export default {
  name: 'IconDeleteFile',
};
</script>

<style lang="scss" module>
.icon {
  width: 22px;
  height: 24px;
}
</style>
