/* eslint-disable */
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class UserService {
  getUserBoard() {
    return axios.get(`${API_URL}/account`, { headers: authHeader() });
  }

  updateUserBoard(user) {
    return axios.post(
      `${API_URL}/user/update`,
      {
        ...user,
      },
      {
        headers: {
          ...authHeader(),
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

export default new UserService();
/* eslint-disable */
