// eslint-disable-next-line
import { createRouter, createWebHistory } from "vue-router";
import LoginPage from '@/pages/LoginPage.vue';
import RegistrationPage from '@/pages/RegistrationPage.vue';
import MatchesPage from '@/pages/MatchesPage.vue';
import ConfirmPage from '@/pages/ConfirmPage.vue';
import ConfirmEmailPage from '@/pages/ConfirmEmailPage.vue';
import ResetPage from '@/pages/ResetPage.vue';
import NotFound from '@/pages/NotFound.vue';
import SettingsPage from '@/pages/SettingsPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsPage,
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationPage,
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: ConfirmPage,
  },
  {
    path: '/confirm/email',
    name: 'confirm-email',
    component: ConfirmEmailPage,
  },
  {
    path: '/matches',
    name: 'matches',
    component: MatchesPage,
  },
  {
    path: '/forgot/email',
    name: 'forgot-email',
    component: ResetPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
});

router.beforeEach((to, from, next) => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  if (!['forgot-email', 'confirm-email', 'login', 'registration']
    .includes(to.name) && !user && !user?.access.token) {
    return next({ name: 'login' });
  }

  return next();
});

export default router;
