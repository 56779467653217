<template>
  <!--eslint-disable-->
  <div class="box">
    <div
      :class="classes"
      @click="loadFile"
      @mouseleave="isHover = false"
      @mouseover="isHover = true"
      @dragenter.prevent="dragenter"
      @dragleave.prevent="dragleave"
      @dragover.prevent="dragover"
      @drop.prevent="drop"
    >
      <form ref="form">
        <input
          ref="file"
          :class="$style.input"
          type="file"
          name="file"
          @change="onChange"
        />
      </form>
      <div
        :class="[
          $style.containerInfo,
          {
            [$style._column]: file,
            [$style._error]: file && isError,
          },
        ]"
      >
        <div
          class="c-base400"
          :class="[$style.label, { [$style.error]: isError }]"
        >
          {{ label }}
        </div>

        <template v-if="file">
          <div :class="$style.loadSection">
            <div v-if="isHover" @click.stop="deleteFile">
              <!--            <IconDeleteFile class="frame-icon _16 c-base1000"/>-->
            </div>
            <div v-else>
              <!--            <IconFile class="frame-icon _16 c-base1000"/>-->
            </div>
            <div :class="[$style.fileName]" class="semibold c-base1000">
              {{ fileName }}
            </div>
          </div>
        </template>

        <template v-else-if="isDragAndDrop">
          <div :class="$style.dragAndDropContainer">
            <!--          <IconUpArrowAndUnderline class="frame-icon _16 c-base0"/>-->
          </div>
        </template>

        <div v-if="file" :class="$style.icon" @click="deleteFile">
          <IconDeleteFile />
        </div>
        <div v-else :class="$style.icon">
          <IconFile />
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-if="isError" :class="$style.fileloader__error">
        <span class="v-input__error-text">
          {{ errorMessage }}
        </span>
      </div>
    </transition>
    <!--    <transition name="fade">-->
    <!--      <div v-if="errorMsg" class="v-input__error">-->
    <!--        <span class="v-input__error-text">{{ errorMsg }}</span>-->
    <!--      </div>-->
    <!--    </transition>-->
  </div>
</template>

<script>
/* eslint-disable */
import { fileTypeValidation } from "@/assets/js/utils/fileUtils";
import IconFile from "@/assets/icons/IconFile";
import IconDeleteFile from "@/assets/icons/IconDeleteFile";

import _ from "lodash";

//
// Компонент работает по 3 сценариям
// 1. когда нет файла и ошибок нет
// 2. когда есть файл и он не проходит валидацию по типу к примеру
// 3. когда нет файла и ошибка генерируется снаружи что файл мы не закрепили
//

export default {
  name: "VFileLoader",

  components: {
    IconFile,
    IconDeleteFile,
  },

  props: {
    name: {
      type: String,
      default: "",
    },

    // форматы файлов которые допущены для добавления
    // нужно пробрасывать массив форматов типа ['pdf']
    // пока работает без библиотеки
    fileFormats: {
      type: Array,
      required: false,
      default: () => ["pdf"],
    },

    label: {
      type: String,
      default: String,
    },

    value: {
      type: String,
      default: "",
    },

    /**
     * Устанавливает класс для определения цвета
     */
    color: {
      type: String,
      default: "default",
    },

    /**
     * Устанавливает класс для определения обратного цвета
     */
    inversed: {
      type: Boolean,
      default: false,
    },

    // отключение компонента
    disabled: {
      type: Boolean,
      default: false,
    },

    // снаружи генерируется ошибка
    error: {
      type: Boolean,
      default: false,
    },

    // текст ошибки, который генерируется снаружи
    errorMsg: {
      type: String,
      default: "",
    },

    modClass: {
      type: String,
      default: "",
    },

    // Максимальный размер загружаемого файла по умолчанию 5mb
    fileMaxSizeMb: {
      type: Number,
      required: false,
      default: 5,
    },
  },

  data() {
    return {
      lazyValue: "",
      isFocused: false,
      file: null,

      isHover: false,
      isDragAndDrop: false,

      // тут будет глобальная ошибка которая будет поступать как снаружи от сервера
      // так и от клиента внутри если валидация на тип файлов к примеру не пройдена
      isError: this.error,
      errorMessage: this.errorMsg,
    };
  },

  computed: {
    classes() {
      return [
        `v-input--${this.color}`,
        this.$style.inputContainer,
        this.modClass,
        {
          "is-active": this.lazyValue,
          "is-focused": this.isFocused,
          "is-disabled": this.disabled,
          "is-inversed": this.inversed,
          "has-error": this.isError,
          "has-label": this.label,
        },
      ];
    },

    fileName() {
      // чтобы было видно и формат файла для ошибок и длина названия не превышала
      const fileName = this.file?.name;
      if (fileName && fileName.length > 20) {
        const format = fileName.substring(fileName.lastIndexOf("."));
        return `${fileName.slice(0, 20)}.... ${format}`;
      } else {
        return fileName;
      }
    },
    // лейбл когда файл загружен первая буква с большой
    labelFirstUpperCase() {
      return this.label ? _.startCase(_.toLower(this.label)) : "";
    },
  },

  // это нужно чтобы при внешних валидациях перед отправкой проверять ошибки
  watch: {
    errorMsg(errorMsg, test) {
      // console.log('errorMsg', errorMsg);
      // console.log('test', test);
      this.isError = true;
      this.errorMessage = errorMsg;
    },

    error(error) {
      this.isError = error;
    },
    async value(value) {
      if (typeof value === "string") {
        async function createFile() {
          let response = await fetch(value);
          let data = await response.blob();
          let metadata = {
            type: "image",
          };
          return new File([data], "image.jpg", metadata);
        }

        this.file = await createFile();
      }
    },
  },

  methods: {
    // Проверка на размер файла
    checkFileSize(file) {
      // Переводим в мегабайты в бинарном виде строго
      const size = parseFloat((file?.size / Math.pow(1024, 2)).toFixed(2));
      // проверяем не превышает ли размер файла установленного лимита
      return this.fileMaxSizeMb > size;
    },

    // проводим валидацию по типу файла и размеру в самом компоненте
    checkValidation(file) {
      // тут будет валидация по типу файла и размеру
      if (
        file &&
        this.fileFormats.length &&
        !fileTypeValidation(this.fileFormats, file)
      ) {
        const typesNameFile = this.fileFormats.join(", ");
        this.isError = true;
        this.errorMessage = `Загружаемый файл не соответствует формату допущенных файлов ${typesNameFile}`;
        this.$emit("change", null);
        // return;
      }

      // тут будет валидация по размеру
      if (file && this.fileMaxSizeMb && !this.checkFileSize(file)) {
        this.isError = true;
        this.errorMessage = `Загружаемый вами файл превышает установленный размер ${this.fileMaxSizeMb} мб`;
        this.$emit("change", null);
        // return;
      }
    },

    // сброс ошибок внутри и снаружи у родителя компонента перед отправкой родителю
    resetAndSendData(file) {
      this.isError = false;
      this.errorMessage = "";
      this.$emit("deleteErrorMsg", "");
      this.$emit("change", file);
    },

    // загрузка по клику сценарий
    onChange() {
      this.isError = false;
      const file = this.$refs.file.files[0];

      this.checkValidation(file);

      this.file = file;

      // наружу бросаем файл если формат соответствует и ошибок нет
      if (!this.isError) {
        // this.isError = false;
        // this.errorMessage = '';
        // this.$emit('deleteErrorMsg', '');
        // this.$emit('change', file);
        this.resetAndSendData(file);
      }
    },

    loadFile() {
      // если файл ранее отсутствует, то только тогда цепляем
      return !this.file ? this.$refs.file.click() : undefined;
    },

    // удаляем файл и сбрасываем форму, чтобы файл с тем же именем сработал второй раз
    deleteFile() {
      // console.log('delete file');
      this.isError = false;
      this.file = null;
      this.$refs.form.reset();
      this.errorMessage = "";
      this.$emit("deleteErrorMsg", "");
      this.$emit("change", null);
    },

    dragenter() {
      // console.log('dragenter');
      this.isError = false;
      this.isDragAndDrop = true;
    },

    dragleave() {
      // console.log('dragleave');
      this.isDragAndDrop = false;
    },

    dragover() {
      // console.log('dragover');
      if (!this.isDragAndDrop) {
        this.isDragAndDrop = true;
      }
    },

    // загрузка по драг энд дроп
    drop(e) {
      // console.log('drop');
      // console.log('e', e);
      this.isDragAndDrop = false;

      if (e.dataTransfer.files.length > 1) {
        this.isError = true;
        this.errorMessage = "Возможна загрузка только одного файла";
        // return;
      }

      const file = e.dataTransfer.files[0];

      this.checkValidation(file);

      this.file = file;

      // наружу бросаем файл если формат соответствует и ошибок нет, и наружную ошибку стираем
      if (!this.isError) {
        // this.isError = false;
        // this.errorMessage = '';
        // this.$emit('deleteErrorMsg', '');
        // this.$emit('change', file);
        this.resetAndSendData(file);
      }
    },
  },
};
</script>

<style lang="scss" module>
.box {
  position: relative;
}

.is-disabled {
  pointer-events: none;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 0 16px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  transition: border-color $default-transition, color $default-transition;

  @include respond-to(sm) {
    min-height: 3.6rem;
    padding: 0 1.6rem;
    font-size: 1.6rem;
  }

  /* Colors */
  &--default {
    &:not(.is-inversed) {
      border-color: $base-0;
      color: $base-0;
      caret-color: $base-0;

      @include hover {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.is-focused {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.has-error {
        border-color: $error;

        .v-input__label {
          color: $error;
        }
      }

      &.is-disabled {
        pointer-events: none;
        border-color: rgba($base-0, 0.1);
        color: rgba($base-0, 0.2);

        .v-input__label {
          color: rgba($base-0, 0.2);
        }
      }

      .v-input__label {
        transform: translateY(-20px) scale(0.7);
      }
    }

    &.is-inversed {
      border-color: $base-100;
      color: $base-0;
      caret-color: $base-0;

      @include hover {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.is-focused {
        border-color: $base-0;

        .v-input__label {
          color: $base-0;
        }
      }

      &.has-error {
        border-color: $error;

        .v-input__label {
          color: $error;
        }
      }

      &.is-disabled {
        pointer-events: none;
        border-color: $base-100;
        color: $base-110;

        .v-input__label {
          color: $base-110;
        }
      }

      .v-input__label {
        color: $base-110;
      }
    }
  }

  /* End colors */

  /* Modificators */

  //&.is-active {
  //    .v-input__premask_wrap {
  //        opacity: .5;
  //
  //        span {
  //            opacity: 0;
  //        }
  //    }
  //
  //    .v-input__label {
  //        transform: translateY(-1.5rem) scale(.7);
  //    }
  //}

  //&.is-focused {
  //    .v-input__label {
  //        transform: translateY(-1.5rem) scale(.7);
  //    }
  //}

  &.has-error {
    .v-input__inner {
      padding-right: 2.4rem;
    }
  }

  &.is-disabled {
    pointer-events: none;
  }

  /* End Modificators */

  //&__premask_wrap {
  //    position: absolute;
  //    top: 0;
  //    left: 0;
  //    z-index: 1;
  //    display: flex;
  //    align-items: center;
  //    width: 100%;
  //    height: calc(100% - 1.7rem);
  //    margin-top: 1.7rem;
  //    font-size: inherit;
  //    color: currentColor;
  //    opacity: 0;
  //    transition: .3s all ease;
  //}

  //&__premask {
  //    display: inline-block;
  //    line-height: 1.15;
  //}

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 1.7rem;
  }

  &__inner-no-padding {
    position: relative;
    width: 100%;
    height: 100%;
  }

  //&__native {
  //    position: relative;
  //    z-index: 2;
  //    width: 100%;
  //    height: 100%;
  //    margin: 0;
  //    padding: 0;
  //    border: none;
  //    background-color: transparent;
  //    outline: none;
  //    font-size: inherit;
  //    font-weight: inherit;
  //    color: currentColor;
  //    box-shadow: none;
  //    -webkit-appearance: none;
  //
  //    &:-webkit-autofill,
  //    &:-webkit-autofill:hover,
  //    &:-webkit-autofill:focus,
  //    &:-webkit-autofill:active {
  //        -webkit-box-shadow: 0 0 0 30px white inset !important; /* stylelint-disable-line declaration-no-important */
  //    }
  //}

  &__label {
    position: absolute;
    bottom: 50%;
    left: 0;
    font-weight: 400;
    pointer-events: none;
    transform: translateY(-20px) scale(0.7);
    transform-origin: left center;
    transition: transform 0.3s ease;
  }
}

.input {
  display: none;
}

.containerInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 50px;
  flex-direction: row;
  cursor: pointer;

  &._column {
    align-items: center;
    justify-content: space-between;
  }

  &._error {
    .labelOnLoad {
      color: $error;
    }
  }
}

.label {
  position: absolute;
  bottom: 50%;
  left: 14px;
  background-color: $white;
  padding: 0 2px;
  font-weight: 400;
  pointer-events: none;
  transform: translateY(-20px) scale(0.7);
  transform-origin: left center;
  transition: transform 0.3s ease;
  color: $base-0;

  &.error {
    color: $error;
  }
}

.labelOnLoad {
  font-weight: 400;
}

.loadSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.fileName {
  margin-left: 1.4rem;
}

.dragAndDropContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33rem;
  height: 4.8rem;
  border-radius: 1.2rem;
  background: $gradient-base;
}

.icon {
  margin-left: auto;
}

.fileloader__error {
  margin-top: 8px;
  font-family: inherit;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: $error;
}
</style>
