<template>
  <button
    :class="[$style.match, {[$style.column]: !match.teams,
    [$style.pointer]: !match.teams && match.status.slug === 'in_process'
    || match.status.slug === 'approve' } ]"
    @click="openDetails(match)"
  >
    <template v-if="match.teams">
      <div :class="$style.block">
        <p :class="$style.date">
          {{ match.date }}
        </p>
        <span :class="$style.stadium">
          {{ match.stadium }}
        </span>
      </div>
      <div :class="$style.block">
        <div :class="$style.wrap">
          <span :class="[$style.teamName, $style._right]">
            {{ match.teams.owner.name }}
          </span>
          <div :class="$style.imgWrapper">
            <img :src="match.teams.owner.logo" alt="">
            <!--          <ImageLazy-->
            <!--            v-if="match.teams.owner.logo"-->
            <!--            :image="match.teams.owner.logo"-->
            <!--            :class="$style.defaultLayout"-->
            <!--            contain-->
            <!--            absolute-->
            <!--            tag="img"-->
            <!--          />-->
          </div>
        </div>
        <div :class="$style.line"/>
        <div :class="[$style.wrap, $style._reverse]">
          <span :class="[$style.teamName, $style._left]">
            {{ match.teams.guest.name }}
          </span>
          <div :class="$style.imgWrapper">
            <img :src="match.teams.guest.logo" alt="">
            <!--          <ImageLazy-->
            <!--            v-if="match.teams.guest.logo"-->
            <!--            :image="match.teams.guest.logo"-->
            <!--            :class="$style.defaultLayout"-->
            <!--            contain-->
            <!--            absolute-->
            <!--            tag="img"-->
            <!--          />-->
          </div>
        </div>
      </div>
      <div :class="$style.block">
        <div :class="$style.imgWrapper" v-if="match.tournament.logo">
          <img :src="match.tournament.logo" alt="">
          <!--        <ImageLazy-->
          <!--          v-if="match.tournament.logo"-->
          <!--          :image="match.tournament.logo"-->
          <!--          :class="$style.defaultLayout"-->
          <!--          contain-->
          <!--          absolute-->
          <!--          tag="img"-->
          <!--        />-->
        </div>
        <div :class="$style.text">
          <span :class="$style.stage">
            {{ match.tournament.name }}
        </span>
          <span :class="$style.stage">
            {{ match.tournament.stage }}
        </span>
        </div>
      </div>
      <div :class="$style.block">
        <VButton
          responsive
          inversed
          :inProgress="match.status.slug === 'in_process'"
          :isAccess="match.status.slug === 'approve'"
          color="primary"
          :disabled="match.has_request && match.status.slug !== 'closed'
          || !match.has_request && match.status.slug === 'expired'"
          @click="openModal"
        >
          <template v-if="!match.has_request && match.status.slug !== 'expired'">
            подать заявку
          </template>
          <template v-else>
            {{ match.status.name }}
          </template>
        </VButton>
      </div>
    </template>
    <template v-else>
      <div :class="$style.wr">
        <div :class="$style.large">
          <p :class="$style.date">
            СЕЗОННАЯ АККРЕДИТАЦИЯ {{ match.title }}
          </p>
        </div>
        <div :class="$style.block">
          <VButton
            responsive
            inversed
            :inProgress="match.status.slug === 'in_process'"
            :isAccess="match.status.slug === 'approve'"
            color="primary"
            :disabled="match.has_request && match.status.slug !== 'closed'
          || !match.has_request && match.status.slug === 'expired'"
            @click="openModalSeasons()"
          >
            <template v-if="!match.has_request && match.status.slug !== 'expired'">
              подать заявку
            </template>
            <template v-else>
              {{ match.status.name }}
            </template>
          </VButton>
        </div>
      </div>
    </template>
  </button>
</template>

<script>

export default {
  name: 'VMatch',
  components: {},

  props: {
    match: {
      type: Object,
      default: () => {
      },
    },
    hasRequest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModal: false,
      showModalSeasons: false,
    };
  },

  methods: {
    openModal() {
      this.showModal = true;
      this.$emit('openModal', this.showModal, this.match);
    },
    openModalSeasons() {
      this.showModalSeasons = true;
      this.$emit('openModalSeasons', this.showModalSeasons);
    },
    openDetails(match) {
      if (match.teams) return;
      if (match.status.slug === 'in_process' || match.status.slug === 'approve') {
        this.$emit('openModalSeasonsDetail', true, match);
      }
    },
  },
};
</script>

<style lang="scss" module>
.match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 15px;
  background-color: $white;
  border-bottom: 1px solid #DEE4EA;
  cursor: default;

  @media screen and (max-width: 1189px) {
    flex-wrap: wrap;
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.block {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1189px) {
    :global(.v-button) {
      font-size: 8px;
    }
  }

  &:first-child {
    width: 15%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 1189px) {
      width: 50%;
    }
  }

  &:nth-child(2) {
    width: 35%;
    @media screen and (max-width: 1189px) {
      width: 50%;
    }
  }

  &:nth-child(3) {
    width: 30%;
    @media screen and (max-width: 1189px) {
      width: 50%;
      margin-top: 25px;
    }
  }

  &:last-child {
    width: 20%;
    @media screen and (max-width: 1189px) {
      width: 50%;
      margin-top: 25px;
    }
  }
}

.large {
  display: flex;
  align-items: center;
  width: 50%;
  @media screen and (max-width: 1189px) {
    :global(.v-button) {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 1189px) {
    width: 50%;
  }
}

.date {
  font-weight: 500;
  font-size: 26px;
  color: $base-0;
  @media screen and (max-width: 1189px) {
    font-size: 18px;
  }
}

.stadium {
  font-weight: 400;
  font-size: 14px;
  color: $base-0;
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }
}

.teamName {
  font-weight: 500;
  font-size: 18px;
  color: $base-0;
  max-width: 140px;
  text-transform: uppercase;
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }

  &._right {
    margin-right: 12px;
    text-align: right;
  }

  &._left {
    margin-left: 12px;
    text-align: left;
  }
}

.line {
  width: 2px;
  height: 60px;
  background-color: $base-10;
  margin: 0 25px;
  @media screen and (max-width: 1189px) {
    margin: 0 10px;
  }
}

.stage {
  font-weight: 500;
  font-size: 16px;
  color: $base-0;
  margin-left: 15px;
  @media screen and (max-width: 1189px) {
    font-size: 12px;
  }
}

.defaultLayout {
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .1;
  }
}

.wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 1189px) {
    flex-direction: column-reverse;
  }

  &._reverse {
    flex-direction: row-reverse;
    @media screen and (max-width: 1189px) {
      flex-direction: column-reverse;
    }
  }
}

.imgWrapper {
  position: relative;
  width: 60px;
  height: 60px;
  @media screen and (max-width: 1189px) {
    width: 25px;
    height: 25px;
  }
}

.text {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.wr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    :nth-child(2) {
      align-self: flex-end;
    }
  }
}

.pointer {
  cursor: pointer;
}

</style>
