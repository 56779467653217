<template>
  <!-- eslint-disable  -->
  <div
    :class="$style.resetForm"
  >
    <template v-if="!successChangePass">
      <h3 :class="$style.title">
        восстановление <br> пароля
      </h3>
      <form v-if="!getHash.hash" @submit.prevent="submitFormLink(user)">
        <div :class="$style.fieldset">
          <div :class="$style.inputWrapper" v-if="!sendEmail">
            <VInput
              v-model="user.email"
              name="email"
              label="E-mail*"
              :disabled="isLoading"
              show-label
              :error-msg="errors.email ? errors.email : message"
              :error="Boolean(errors.email)"
              @input="errors.email = ''"
            />
          </div>

          <p v-if="sendEmail"> Ссылка для восстановления пароля отправлена на почту {{ user.email }}</p>
          <VButton v-if="!sendEmail"
            color="primary"
            type="submit"
            responsive
            :disabled="this.isLoading"
          >
            дальше
          </VButton>

        </div>
      </form>
      <form v-if="getHash.hash" @submit.prevent="submitFormRecovery(getHash)">
        <div :class="$style.fieldset">
          <div :class="$style.inputWrapper">
            <VInput
              v-model="user.password"
              name="password"
              :type="typeInput"
              icon
              label="Введите новый пароль*"
              :disabled="isLoading"
              show-label
              :error-msg="errors.password"
              :error="Boolean(errors.password)"
              @input="errors.password = ''"
              @showPass="showHidePass"
            />
          </div>
          <div :class="[$style.inputWrapper]">
            <VInput
              v-model="user.password_confirmation"
              name="password_confirmation"
              :type="typeInput"
              icon
              label="Введите пароль ещё раз*"
              :disabled="isLoading"
              show-label
              :error-msg="errors.password_confirmation"
              :error="Boolean(errors.password_confirmation)"
              @input="errors.password_confirmation = ''"
              @showPass="showHidePass"
            />
          </div>
          <VButton
            color="primary"
            type="submit"
            responsive
          >
            Изменить пароль и войти
          </VButton>
        </div>
      </form>
    </template>
    <template v-else>
      <h3 :class="$style.title">
        Пароль успешно изменен
      </h3>
      <div :class="$style.link">
        Авторизуйтесь для
        <router-link to="/login">входа</router-link>
      </div>
    </template>
  </div>
</template>

<script>

const regexPass = /(?=.{6,})/;
const regexEmail = /(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}\b/;

export default {
  name: 'ResetForm',

  data() {
    return {
      successChangePass: false,
      sendEmail: false,
      isValidPass: false,
      showPassword: false,
      typeInput: 'password',
      isFormEnable: null,
      message: '',
      user: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
      errors: {
        email: '',
        password: '',
        password_confirmation: '',
      },
    };
  },

  computed: {
    getHash() {
      return {
        email: this.$route.query.email,
        hash: this.$route.query.hash,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
      };
    },
  },
  methods: {
    showHidePass(value) {
      if (value === 'password') {
        this.typeInput = 'text';
      } else {
        this.typeInput = 'password';
      }
    },

    submitFormRecovery(user) {
      this.checkPassword();
      this.checkForm();

      if (this.isFormEnable) {
        this.handleRecovery(user);
      }
    },

    submitFormLink(user) {
      this.checkEmail();
      this.checkForm();

      if (this.isFormEnable) {
        this.handleLink(user);
      }
    },

    checkForm() {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line
      Object.values(this.errors).every((x) => !x) ? this.isFormEnable = true : this.isFormEnable = false;
    },

    validValue(value, regex) {
      return regex.test(value);
    },

    checkPassword() {
      this.errors.password = '';
      if (!this.user.password) {
        this.errors.password = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.password, regexPass)) {
        this.errors.password = 'Пароль должен состоять из 6 символов и более';
      } else if (this.user.password !== this.user.password_confirmation) {
        this.errors.password = 'Пароли не совпадают';
      }
    },

    checkEmail() {
      this.errors.email = '';
      if (!this.user.email) {
        this.errors.email = 'Это поле является обязательным';
      } else if (!this.validValue(this.user.email, regexEmail)) {
        this.errors.email = 'Заполните поле корректно';
      }
    },

    handleLink(user) {
      this.$store.dispatch('auth/link', user)
        .then(
          () => {
            this.sendEmail = true;
          },
          (error) => {
            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                // eslint-disable-next-line prefer-destructuring
                this.errors[key] = value[0];
              });
            }
            this.message = error.response.data.message ?? '';
          },
        );
    },

    handleRecovery(user) {
      this.$store.dispatch('auth/recovery', user)
        .then(
          () => {
            this.showPassword = true;
            this.successChangePass = true;
          },
          (error) => {
            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                // eslint-disable-next-line prefer-destructuring
                this.errors[key] = value[0];
              });
            }
            this.message = error.response.data.message ?? '';
          },
        );
    },
  },
};
</script>

<style lang="scss" module>
.resetForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  form {
    width: 100%;
    max-width: 480px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: block;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: $base-0;
      max-width: 370px;
      margin: 40px auto;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'case';
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.inputWrapper {
  width: 100%;
  max-width: 440px;
  margin: 0 auto 35px;
}

.line {
  width: 100%;
  max-width: 480px;
  height: 1px;
  background-color: $base-0;
  margin: 20px auto 20px;
}

.stepsRecovery {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 430px;
  height: 1px;
  background-color: $base-0;
  margin: 20px auto 10px;
}

.stepsRecoveryText {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 430px;
  margin: 0 auto 20px;

  span {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:nth-child(2) {
      transform: translateX(30px);
    }
  }
}

.step {
  position: relative;
  width: 10px;
  height: 10px;
  border: 1px solid $base-0;
  background-color: $white;
  border-radius: 50%;

  &._active {
    background-color: $base-0;
  }

  &._next {
    background-color: #CEDBE5;
  }
}

.fieldset {
  margin-top: 40px;
}

.link {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: $base-0;
  max-width: 370px;
  margin: 20px auto;

  a {
    text-decoration: underline;
  }
}

</style>
