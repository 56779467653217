<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
    <circle cx="42.5" cy="42.5" r="31.875" stroke="#0959A1" stroke-width="1.5"/>
    <path d="M28.3333 42.5L38.9583 53.125L56.6666 31.875" stroke="#0959A1" stroke-width="1.5"/>
  </svg>
</template>

<script>
/* eslint-disable */
export default {
  name: 'IconSuccessful',
};
</script>

<style lang="scss" module>
.icon {
  width: 85px;
  height: 85px;
}
</style>
