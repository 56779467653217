<template>
  <div
    :class="$style.resetPage"
  >
    <ResetForm/>
  </div>
</template>

<script>
import ResetForm from '@/components/ResetForm.vue';

export default {
  name: 'ResetPage',
  components: {
    ResetForm,
  },
};
</script>

<style lang="scss" module>
.resetPage {

}
</style>
