<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <path d="M2.95227 39.0476C5.90465 42 10.6564 42 20.16 42H21.84C31.3436 42 36.0954 42 39.0477 39.0476C42 36.0954 42 31.3435 42 21.8399V20.16C42 10.6564 42 5.90464 39.0477 2.95226C36.0954 0 31.3436 0 21.84 0H20.16C10.6564 0 5.90465 0 2.95227 2.95226C1.11926e-06 5.90464 0 10.6564 0 20.16V21.8399C0 31.3435 1.11926e-06 36.0954 2.95227 39.0476Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.189 28.8142L30.0376 28.0132C29.6581 27.9024 29.3902 27.5551 29.3767 27.1514L28.9834 15.351C28.8696 13.7892 27.73 12.5469 26.5392 12.1811C26.4723 12.1606 26.4009 12.1883 26.3613 12.2468C26.321 12.3063 26.3367 12.3886 26.3927 12.4328C26.6869 12.6645 27.4962 13.4202 27.4962 14.7238L27.4939 30.1525C27.4939 31.6422 26.1101 32.7348 24.6829 32.3721L21.4828 31.5588C21.128 31.4326 20.8814 31.0964 20.8685 30.7085L20.4753 18.9074C20.3615 17.3456 19.2219 16.1034 18.0311 15.7375C17.9642 15.7171 17.8928 15.7446 17.8532 15.8031C17.813 15.8627 17.8286 15.945 17.8846 15.9892C18.1788 16.2208 18.9881 16.9766 18.9881 18.2802L18.9858 31.1407L18.9876 31.141V33.7103C18.9876 35.2 17.6037 36.2928 16.1765 35.93L7.13768 33.6329C5.88103 33.3135 5 32.1699 5 30.8582V15.4055C5 13.9157 6.38372 12.8231 7.81097 13.1858L13.5064 14.6333V11.8476C13.5064 10.3579 14.8901 9.26518 16.3174 9.62799L22.0124 11.0754V8.28969C22.0124 6.79996 23.3961 5.70721 24.8235 6.07003L33.8623 8.36714C35.119 8.68652 36 9.83011 36 11.1418V26.5945C36 28.0842 34.6163 29.1769 33.189 28.8142Z" fill="#1650B1"/>
  </svg>
</template>

<script>
export default {
  name: 'IconRustore',
};
</script>

<style lang="scss" module></style>
