<template>
  <div
    :class="$style.confirmPage"
  >
      <div>
        <IconSuccessful/>
      </div>
      <h1 :class="$style.title">
        Вы успешно зарегистрировались
      </h1>
      <p :class="$style.description">
        Отправили вам письмо для подтверждения вашего Email
      </p>
  </div>

</template>

<script>
import IconSuccessful from '@/assets/icons/IconSuccessful.vue';

export default {
  name: 'ConfirmPage',

  components: {
    IconSuccessful,
  },
};
</script>

<style lang="scss" module>
.confirmPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 200px;
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $base-0;
}
</style>
