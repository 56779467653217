<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <rect width="42" height="42" rx="11" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3485 11.2353C26.3156 10.1144 26.9674 8.55327 26.789 7C25.3954 7.05306 23.7094 7.89026 22.7101 9.00979C21.8127 10.0033 21.0294 11.5908 21.24 13.1136C22.7946 13.2289 24.3814 12.3574 25.3485 11.2353ZM28.8346 21.0938C28.8735 25.1089 32.5098 26.4446 32.55 26.4618C32.5205 26.556 31.9692 28.365 30.6346 30.2353C29.4797 31.8509 28.2819 33.46 26.3947 33.4945C24.541 33.5276 23.9441 32.4413 21.8235 32.4413C19.7042 32.4413 19.0416 33.4599 17.2871 33.5275C15.4656 33.5925 14.0773 31.7795 12.9144 30.1692C10.5349 26.8756 8.71739 20.8617 11.1586 16.8028C12.3712 14.7879 14.5374 13.51 16.8901 13.4782C18.6781 13.445 20.3668 14.6311 21.46 14.6311C22.5531 14.6311 24.6054 13.2052 26.7622 13.4147C27.6649 13.4506 30.2 13.7635 31.827 16.0463C31.6956 16.1246 28.8024 17.7378 28.8346 21.0938Z" fill="#1650B1"/>
  </svg>
</template>

<script>
export default {
  name: 'IconIos',
};
</script>

<style lang="scss" module></style>
