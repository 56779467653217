<template>
  <div :class="$style.header">
    <div class="container" :class="$style.wrapper">
      <div>
        <LogoBrand />
      </div>
      <p :class="$style.titleHeader">Центр аккредитации</p>
      <button
        @click="handleUserClick"
        :class="$style.user"
        v-if="user?.surname && user?.name"
      >
        <IconUser />
        <span>{{ user.surname }} {{ user.name }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import LogoBrand from '@/assets/icons/LogoBrand.vue';
import IconUser from '@/assets/icons/IconUser.vue';

export default {
  name: 'VHeader',
  components: {
    LogoBrand,
    IconUser,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleUserClick() {
      this.$router.push('settings');
    },
  },
};
</script>

<style lang="scss" module>
.header {
  display: flex;
  width: 100%;
  height: 100px;
  padding: 15px 0;
  background-color: $base-0;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.titleHeader {
  color: $white;
  font-weight: 400;
  font-size: 28px;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: auto;
  color: $white;
  font-weight: 500;
  font-size: 20px;

  span {
    margin-left: 15px;
  }
}
</style>
