<template>
  <!--eslint-disable-->
  <div
    :class="$style.ProposalSeasonsForm"
  >
    <template v-if="!isRequest">
      <h3 :class="$style.title">
        Информация по заявке
      </h3>
      <form
        :class="$style.form"
        @submit.prevent="submitForm(user)"
      >
        <div :class="$style.fieldset">
          <div
            :class="[$style.inputWrapper, $style._row]">
            <VInput
              :value="userLoad.surname"
              preset-value
              name="surname"
              label="Фамилия"
              disabled
              show-label
            />
            <VInput
              :value="userLoad.name"
              preset-value
              name="name"
              label="Имя"
              disabled
              show-label
            />
          </div>
          <div :class="$style.inputWrapper">
            <VInput
              :value="userLoad.patronymic"
              name="surname"
              preset-value
              label="Отчество"
              disabled
              show-label
            />
          </div>
          <div :class="[$style.inputWrapper]">
            <VInput
              :value="userLoad.phone"
              preset-value
              name="phone"
              mask="phone"
              label="Номер телефона*"
              disabled
              show-label
              type="tel"
            />
          </div>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            :value="userLoad.edition"
            preset-value
            label="Издание*"
            disabled
            show-label
          />
        </div>

        <p :class="$style.text">Прикрепите ссылки на публикации о ФК “Динамо” исключительно вашего авторства не менее 10 штук.</p>
        <div :class="[$style.inputWrapper, {[$style.last]: user.links.length - 1 === index && status.slug === 'in_process'}]" v-for="(link, index) in user.links">
          <VInput
              v-model="user.links[index].link"
              :value="user.links[index].link"
              preset-value
              :label="index + 1 > 10 ? 'Ссылка на публикацию' : 'Ссылка на публикацию*'"
              show-label
              :disabled="status.slug !== 'in_process'"
              :error-msg="errors[`links.${index}`]"
              :error="Boolean(errors[`links.${index}`])"
              @input="errors[`links.${index}`] = ''"
          />
        </div>

        <button v-if="status.slug === 'in_process'" :class="$style['link-button']" @click="handleLinkAdd" type="button">Добавить дополнительные ссылки на публикации</button>

        <div :class="$style.inputWrapper">
          <div :class="$style.labelSelect">
            <span>Тип аккредитации*</span>
          </div>
          <Multiselect
            name="mode_accreditation"
            label="title"
            :class="$style.select"
            v-model="user.type_accreditation"
            :options="['Фото', 'Пресса']"
            ref="multiselect"
            disabled
          />
        </div>
        <div
          v-if="userLoad.fan_card"
          :class="$style.inputWrapper"
        >
          <VInput
            :value="userLoad.fan_card"
            preset-value
            name="fan_card"
            type="number"
            label="Номер карты болельщика*"
            disabled
            show-label
          />
        </div>
         <div
          v-else
          :class="$style.inputWrapper"
        >
          <VInput
            v-model="user.fan_card"
            name="fan_card"
            type="number"
            label="Номер карты болельщика*"
            show-label
          />
        </div>
        <div
          v-if="!userLoad.photo"
          :class="$style.inputWrapper"
        >
          <VFileLoader
            :class="$style.input"
            v-model="user.photo"
            name="photo"
            label="Фото*"
            :file-formats="['png', 'jpeg']"
          />
        </div>
        <VButton
            v-if="status.slug === 'in_process'"
            color="primary"
            type="submit"
            responsive
            :disabled="isLoading"
        >
          отправить
        </VButton>
      </form>
    </template>
    <template v-if="isRequest">
      <div :class="$style.message">
        спасибо! <br>
        ваша заявка изменена
      </div>
      <VButton
          :class="$style.btn"
          color="primary"
          responsive
          @click="$emit('close')"
      >
        Понятно
      </VButton>
    </template>

  </div>
</template>

<script>
// eslint-disable-next-line
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
  name: 'ProposalSeasonsForm',

  components: {
    Multiselect,
  },

  props: {
    userLoad: {
      type: Object,
      default: () => {
      },
    },
    uuid: {
      type: String,
      default: '',
    },
    status: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      message: '',
      isRequest: false,
      isFormEnable: null,
      isLoading: false,
      user: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        fan_card: '',
        type_accreditation: '',
        photo: null,
        links: [{ link: '' }, { link: '' }, { link: '' }, { link: '' }, { link: '' }, { link: '' }, { link: '' }, { link: '' }, { link: '' }, { link: '' }],
      },

      errors: {
        links: '',
      },
    };
  },

  computed: {
    createOptions() {
      return this.options.map((el) => ({
        ...el,
        id: el.id,
        value: el.title,
        label: el.title,
      }));
    },
  },
  created() {
    this.asyncData();
  },

  methods: {
    async asyncData() {
      const options = await axios.get(`${process.env.VUE_APP_API_URL}/accreditation/${this.uuid}/info`, {
        headers: {
          ...authHeader(),
          Accept: 'application/json',
        },
      }).catch((e) => {
        if (e.response.status === 401) {
          this.$router.push('/login');
          sessionStorage.removeItem('user');
        }
      });
      // eslint-disable-next-line camelcase
      const { type_accreditation, links } = options.data.data;

      this.user = { ...this.user, type_accreditation, links };
    },

    submitForm(user) {
      this.checkForm();

      if (this.isFormEnable) {
        this.handleProposal(user);
      }
    },

    handleLinkAdd() {
      this.user.links = [...this.user.links, { link: '' }];
    },

    checkForm() {
      // eslint-disable-next-line no-unused-expressions
      // eslint-disable-next-line
      Object.values(this.errors).every((x) => !x) ? this.isFormEnable = true : this.isFormEnable = false;
    },

    handleProposal(user) {
      this.isLoading = true;
      this.user.uuid = this.uuid;
      this.$store.dispatch('auth/updateSeasonsRequest', { ...user })
        .then(
          (response) => {
            this.isRequest = true;
            this.$emit('isRequest', this.isRequest);
            this.message = response.message ?? '';
          },
          (error) => {
            this.isLoading = false;
            // eslint-disable-next-line
                if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(([key, value]) => {
                // eslint-disable-next-line prefer-destructuring
                this.errors[key] = value[0];
              });
            }
            this.message = error.response.data.message ?? '';
          },
        );
    },
  },
};
</script>
<style lang="scss" module>
.ProposalSeasonsForm {
  width: 100%;

  .form {
    width: 100%;

    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: block;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: 'case';

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.inputWrapper {
  width: 100%;
  max-width: 365px;
  margin: 0 auto 35px;

  &._error {
    margin-bottom: 70px;
  }

  &.last {
    margin: 0 auto 15px;
  }

  p {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: $base-0;

    &._error {
      margin-top: 10px;
      color: $error;
    }
  }

  .titleRadio {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 15px;
  }

  .labelSelect {
    position: relative;

    span {
      z-index: 20;
      font-size: 14px;
      position: absolute;
      bottom: 50%;
      left: 14px;
      background-color: $white;
      padding: 0 2px;
      font-weight: 400;
      pointer-events: none;
      transform-origin: left center;
      transition: transform .3s ease;
      color: $base-0;
      transform: translateY(10px) scale(.7);
    }
  }

  &._row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    :global(.v-input) {
      width: calc(50% - 8px);
    }
  }

  ._mt18 {
    margin-top: 18px;
  }

  ._mt10 {
    margin-top: 10px;
  }

  .select {
    min-height: 50px;
    border-color: $base-0;

    &:global(.is-disabled) {
      border-color: rgba($base-0, .1);
      background-color: $white;
      color: rgba($base-0, .2);
    }
  }
}

.wrap {
  border: 1px solid $base-0;
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 12px;

  a {
    text-decoration: underline;
    font-weight: 600;
  }
}

.radioBtn {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $base-0;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 14px;

  &:nth-child(2) {
    margin-right: 10px;
  }

  &._active {
    background-color: $base-0;
    color: $white;
  }
}

.message {
  font-weight: 600;
  font-size: 21px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.btn {
  margin-top: 30px;
}

.text {
  margin: 0 20px 20px;
  font-size: 12px;
  line-height: 18px;
}

.text-error {
  color: $error;
  margin: 0 20px 20px;
  font-size: 12px;
  line-height: 18px;
}

.link-button {
  font-size: 12px;
  line-height: 150%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin: 0 20px 20px;
}
</style>
