<template>
  <div
       :class="['image-lazy', classes]"
  >
    <template v-if="tag === 'img'">
      <img
           :src="image"
           class="image-lazy__image"
           :alt="alt"
      >
    </template>
  </div>
</template>

<script>
export default {
  name: 'ImageLazy',

  props: {
    preview: {
      type: String,
      default: '',
    },

    image: {
      type: String,
      default: '',
    },

    tag: {
      type: String,
      default: 'div',
      validator(value) {
        return ['div', 'img'].includes(value);
      },
    },

    relative: {
      type: Boolean,
      default: false,
    },

    absolute: {
      type: Boolean,
      default: false,
    },

    contain: {
      type: Boolean,
      default: false,
    },

    coverBg: {
      type: Boolean,
      default: false,
    },

    isSwiperSlider: {
      type: Boolean,
      default: false,
    },

    alt: {
      type: String,
      default: '',
    },

    blendMod: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'is-relative': this.relative,
        'is-absolute': this.absolute,
        'is-contain': this.contain,
        'is-cover-bg': this.coverBg,
        'is-blend': this.blendMod,
      };
    },
  },
};
</script>

<style lang="scss">
.image-lazy {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.is-relative {
    position: relative;
  }

  &.is-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.is-contain {
    background-size: contain;

    .image-lazy__image {
      width: 100%;
      background-size: contain;
      object-fit: contain;
    }

    &.is-cover-bg {
      background-size: cover;
    }
  }

  &.is-blend {
    mix-blend-mode: screen;

    .image-lazy__image {
      mix-blend-mode: screen;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    opacity: 0;
    transition: opacity .3s ease .1s;

    &[lazy="loaded"] {
      opacity: 1;
    }

    &.swiper-lazy-loaded {
      opacity: 1;
    }
  }
}
</style>
