<template>
  <multiselect  :options="options" label="title" track-by="title"></multiselect>
</template>

<script>
// eslint-disable-next-line
import Multiselect from 'vue-multiselect'

export default {
  name: 'VSelect',
  components: {
    Multiselect,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    options: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" module>
.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 325px;
  padding: 40px 20px 20px;
  background-color: $base-0;
}
</style>
