<template>
  <div :class="$style.settingsPage">
    <button :class="$style.back" @click="handleBack">
      <IconBack />
      Назад
    </button>
    <SettingsForm />
  </div>
</template>

<script>
import SettingsForm from '@/components/SettingsForm.vue';
import IconBack from '@/assets/icons/IconBack.vue';

export default {
  name: 'SettingsPage',
  components: {
    SettingsForm,
    IconBack,
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" module>
.settingsPage {
  position: relative;
}

.back {
  position: absolute;
  top: -28px;
  left: 33px;
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;

  @media (min-width: 768px) {
    top: 9px;
    left: 50%;
    transform: translateX(-412px);
  }
}
</style>
