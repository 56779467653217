<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <g clip-path="url(#clip0_507_968)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7845 0C3.15596 0 0 3.15541 0 11.7822V30.2178C0 38.8446 3.15596 42 11.7845 42H30.2099C38.8382 42 42 38.8446 42 30.2178V11.7822C42 3.15541 38.844 0 30.2155 0H11.7845Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3203 24.3591H20.0065L19.1605 22.3918L18.3203 24.3591ZM17.9051 25.3506L17.4048 26.4952H16.2659L18.6879 21.0003H19.6723L22.0845 26.4952H20.9152L20.4213 25.3506H17.9051ZM36.2326 26.4912H37.3334V21H36.2326V26.4912ZM31.8513 24.1323H33.8804V23.1311H31.8513V22.0056H34.7969V21.004H30.7509V26.4949H34.9029V25.4933H31.8513V24.1323ZM27.4775 24.7811L26.2295 21H25.3187L24.0708 24.7811L22.8562 21.003H21.6686L23.5853 26.4983H24.5086L25.7586 22.8887L27.0086 26.4983H27.9399L29.8516 21.003H28.6947L27.4775 24.7811ZM14.5855 24.1475C14.5855 25.0413 14.1416 25.519 13.3355 25.519C12.525 25.519 12.0787 25.0275 12.0787 24.1093V21.0037H10.963V24.1475C10.963 25.6939 11.8225 26.5807 13.3203 26.5807C14.8329 26.5807 15.7001 25.677 15.7001 24.1016V21.0003H14.5855V24.1475ZM8.30133 21.0003H9.41631V26.4986H8.30133V24.2656H5.78241V26.4986H4.66675V21.0003H5.78241V23.2182H8.30133V21.0003Z" fill="#1650B1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21 14C17.1398 14 14 10.8599 14 7H14.9888C14.9888 10.3143 17.6857 13.0109 21 13.0109C24.3143 13.0109 27.0112 10.3143 27.0112 7H28C28 10.8599 24.8599 14 21 14Z" fill="#1650B1"/>
    </g>
    <defs>
      <clipPath id="clip0_507_968">
        <rect width="42" height="42" rx="11" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconHuawei',
};
</script>

<style lang="scss" module></style>
