<template>
  <div :class="$style.notFound">
    <h3>Страница не найдена</h3>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" module>
.notFound {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
