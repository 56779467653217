<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
    <rect width="42" height="42" rx="11" fill="white"/>
    <path d="M24.3026 19.985L13.7807 9.60949L27.1679 17.1672L24.3026 19.985ZM11.0346 9C10.4148 9.31881 10 9.90018 10 10.655V31.345C10 32.0998 10.4148 32.6812 11.0346 33L23.2681 20.9977L11.0346 9ZM31.3062 19.5771L28.4981 17.9783L25.3658 21.0023L28.4981 24.0264L31.3634 22.4276C32.2215 21.7572 32.2215 20.2475 31.3062 19.5771ZM13.7807 32.3952L27.1679 24.8375L24.3026 22.0197L13.7807 32.3952Z" fill="#1650B1"/>
  </svg>
</template>

<script>
export default {
  name: 'IconAndroid',
};
</script>

<style lang="scss" module></style>
