<template>
  <!--eslint-disable-->
  <div :class="$style.SettingsForm">
    <h3 :class="$style.title">Настройки профиля</h3>
    <form :class="$style.form" @submit.prevent="handleUpdate(user)">
      <div :class="$style.fieldset">
        <div
          :class="[
            $style.inputWrapper,
            $style._row,
            {
              [$style._error]: Boolean(errors.surname) || Boolean(errors.name),
            },
          ]"
        >
          <VInput
            v-model="user.surname"
            :value="user.surname"
            name="surname"
            label="Фамилия*"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.surname"
            :error="Boolean(errors.surname)"
            @input="errors.surname = ''"
          />
          <VInput
            v-model="user.name"
            :value="user.name"
            name="name"
            label="Имя*"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.name"
            :error="Boolean(errors.name)"
            @input="errors.name = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.patronymic"
            :value="user.patronymic"
            name="surname"
            label="Отчество"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.patronymic"
            :error="Boolean(errors.patronymic)"
            @input="errors.patronymic = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.birth_date"
            :value="user.birth_date"
            name="birth_date"
            mask="date"
            placeholder="ДД/ММ/ГГГГ"
            label="Дата рождения*"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.birth_date"
            :error="Boolean(errors.birth_date)"
            @input="errors.birth_date = ''"
          />
        </div>
        <div :class="$style.inputWrapper">
          <template v-if="!isHideSelect">
            <div :class="$style.labelSelect">
              <span>Издание*</span>
            </div>
            <Multiselect
              name="edition_id"
              label="title"
              :object="true"
              :class="$style.select"
              v-model="edition_id"
              :value="user.edition_id"
              :searchable="true"
              :options="createOptions"
              :disabled="isLoading || !isFormEnable"
              ref="multiselect"
            />
          </template>
          <template v-if="isHideSelect">
            <VInput
              v-model="edition"
              name="edition"
              label="Издание*"
              :disabled="isLoading || !isFormEnable"
              show-label
              :error-msg="errors.edition"
              :error="Boolean(errors.edition)"
              @input="errors.edition = ''"
            />
          </template>
          <div :class="$style._mt10">
            <VCheckbox
              value=""
              :disabled="isLoading || !isFormEnable"
              @change="isShownSelect"
            >
              Моего издания нет в списке
            </VCheckbox>
          </div>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.fan_card"
            :value="user.fan_card"
            name="fan_card"
            mask="fan_card"
            label="Номер карты болельщика"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.fan_card"
            :error="Boolean(errors.fan_card)"
            @input="errors.fan_card = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.fan_card) }">
            Нет карты болельщика?
            <a href="https://fcdm.ru/fanid">Узнайте как получить ее</a>
          </p>
        </div>
      </div>
      <div :class="$style.fieldset">
        <div :class="[$style.inputWrapper]">
          <VInput
            v-model="user.phone"
            :value="user.phone"
            name="phone"
            mask="phone"
            label="Номер телефона*"
            :disabled="isLoading || !isFormEnable"
            show-label
            type="tel"
            :error-msg="errors.phone"
            :error="Boolean(errors.phone)"
            @input="errors.phone = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.phone) }">
            Должен соответствовать номеру телефона, указанному в госуслугах
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.contact_phone"
            :value="user.contact_phone"
            name="contact_phone"
            mask="phone"
            type="tel"
            label="Контактный номер"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.contact_phone"
            :error="Boolean(errors.contact_phone)"
            @input="errors.contact_phone = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.contact_phone) }">
            Предпочитаемый вид связи
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VInput
            v-model="user.email"
            :value="user.email"
            name="email"
            label="E-mail*"
            :disabled="isLoading || !isFormEnable"
            show-label
            :error-msg="errors.email"
            :error="Boolean(errors.email)"
            @input="errors.email = ''"
          />
          <p :class="{ [$style._error]: Boolean(errors.email) }">
            На данную почту будут приходить уведомления о результатах
            рассмотрения заявок
          </p>
        </div>
        <div :class="$style.inputWrapper">
          <VFileLoader
            :class="$style.input"
            @change="getFile"
            :value="user.photo"
            name="photo"
            label="Фото*"
            :file-formats="['png', 'jpeg']"
            @input="errors.photo = ''"
            :disabled="isLoading || !isFormEnable"
            :error-msg="errors.photo"
            :error="Boolean(errors.photo)"
          />
          <p :class="$style.info">Формат 3х4, jpg/png, размер не более 5Мб, на белом фоне</p>
          <img
            :class="$style.photo"
            v-if="thumbnail"
            :src="thumbnail.url"
            :alt="thumbnail.name"
            :width="48"
            :height="48"
          />
        </div>
      </div>
      <VButton
        v-if="!isFormEnable"
        color="primary"
        responsive
        @click="isFormEnable = true"
      >
        редактировать
      </VButton>
      <div v-else :class="$style.buttons">
        <VButton
          inversed
          color="primary"
          responsive
          @click="handleCancel"
          :disabled="isLoading || !isFormEnable"
        >
          отменить
        </VButton>
        <VButton
          color="primary"
          type="submit"
          responsive
          :disabled="isLoading || !isFormEnable"
        >
          сохранить изменения
        </VButton>
      </div>
    </form>
  </div>
</template>

<script>
// eslint-disable-next-line
import Multiselect from "@vueform/multiselect";
import axios from 'axios';

export default {
  name: 'SettingsForm',
  components: {
    Multiselect,
  },
  data() {
    return {
      isHideSelect: false,
      message: '',
      edition: '',
      edition_id: {},
      options: [],
      isFormEnable: false,
      user: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        contact_phone: '',
        email: '',
        birth_date: '',
        fan_card: '',
        photo: null,
      },
      isLoading: false,
      errors: {
        name: '',
        surname: '',
        patronymic: '',
        phone: '',
        contact_phone: '',
        email: '',
        birth_date: '',
        edition_id: '',
        edition: '',
        photo: '',
      },
    };
  },

  computed: {
    createOptions() {
      return this.options
        .map((el) => ({
          ...el,
          id: el.id,
          value: el.title,
          label: el.title,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },

    setPropertyNameEdition() {
      return this.isHideSelect
        ? { edition: this.edition }
        : { edition_id: this.edition_id.id };
    },
    getUser() {
      return this.$store.state.user.user;
    },
    thumbnail() {
      const { photo } = this.user;

      if (photo) {
        if (typeof photo === 'string') {
          return {
            name: '',
            url: photo,
          };
        }

        if ('File' in window && photo instanceof File) {
          return {
            name: photo.name,
            url: URL.createObjectURL(photo),
          };
        }
      }

      return undefined;
    },
  },
  async created() {
    try {
      this.asyncData();
      this.$store.dispatch('user/getUser');
    } catch (err) {
      if (err.response.status === 401) {
        this.$router.push('/login');
        sessionStorage.removeItem('user');
      }
    }
  },
  methods: {
    isShownSelect(val) {
      this.isHideSelect = val;
    },

    getFile(value) {
      if (value === null || ('File' in window && value instanceof File)) {
        this.user.photo = value;
      }
    },

    handleCancel() {
      this.isFormEnable = false;
      this.defaultUserData();
    },
    defaultUserData() {
      /* eslint-disable */
      const {
        name,
        surname,
        patronymic,
        contact_phone,
        email,
        birth_date,
        fan_card,
        photo,
        phone,
        edition_id,
        edition,
      } = this.getUser;

      if (this.getUser) {
        if (edition_id && edition) {
          this.edition_id = {
            id: edition_id,
            title: edition,
            value: edition,
            label: edition,
          };
        } else {
          this.isHideSelect = true;
        }
        this.user = {
          name,
          surname,
          patronymic,
          phone,
          contact_phone,
          email,
          birth_date,
          fan_card,
          photo,
        };
      }
      /* eslint-enable */
    },

    async asyncData() {
      const options = await axios.get(
        `${process.env.VUE_APP_API_URL}/references/editions`,
      );
      this.options = [...options.data.data];
    },
    handleUpdate(user) {
      this.isLoading = true;
      let payload = user;

      if (this.getUser.photo === user.photo) {
        payload = {
          ...payload,
          photo: undefined,
        };
      }

      this.$store
        .dispatch('user/updateUser', {
          ...payload,
          ...this.setPropertyNameEdition,
        })
        .then(
          (res) => {
            this.$toast.success(res.message);
          },
          (error) => {
            this.$toast.error(error.response.data.message);

            // eslint-disable-next-line
            if (error.response.data.errors) {
              this.errors = error.response.data.errors ?? {};
              Object.entries(error.response.data.errors).forEach(
                ([key, value]) => {
                  // eslint-disable-next-line prefer-destructuring
                  this.errors[key] = value[0];
                },
              );
            }
          },
        )
        .finally(() => {
          this.isLoading = false;
          this.isFormEnable = false;
        });
    },
  },
  watch: {
    getUser() {
      this.defaultUserData();
    },
  },
};
</script>
<style lang="scss" module>
.SettingsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 104px;
    row-gap: 50px;
    width: 100%;
    max-width: 834px;
    margin: 40px auto 0;

    @media screen and (max-width: 767px) {
      display: grid;
      gap: 35px;
      grid-template-columns: 1fr;
      max-width: 365px;
    }
  }
}

.title {
  color: $base-0;
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  font-feature-settings: "case";

  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.fieldset {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.buttons {
  display: grid;
  grid-template-columns: 115px 1fr;
  gap: 17px;
  max-width: 365px;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 365px;
  margin: 0 auto;

  &._error {
    margin-bottom: 70px;
  }

  p {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: $base-0;
    margin-top: 8px;

    &._error {
      margin-top: 35px;
    }

    a {
      text-decoration: underline;
    }
  }

  .labelSelect {
    position: relative;

    span {
      z-index: 20;
      font-size: 14px;
      position: absolute;
      bottom: 50%;
      left: 14px;
      background-color: $white;
      padding: 0 2px;
      font-weight: 400;
      pointer-events: none;
      transform-origin: left center;
      transition: transform 0.3s ease;
      color: $base-0;
      transform: translateY(10px) scale(0.7);
    }
  }

  &._row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    :global(.v-input) {
      width: calc(50% - 8px);
    }
  }

  ._mt18 {
    margin-top: 18px;
  }

  ._mt10 {
    margin-top: 10px;
  }

  .select {
    min-height: 50px;

    &:global(.is-active) {
      box-shadow: none;
    }

    &:global(.multiselect-option.is-selected) {
      background: $base-0;
    }
  }

  .photo {
    margin-top: 10px;
    object-fit: cover;
  }

  .info {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
  }
}
</style>
