<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" :class="$style.icon">
    <line x1="2.06066" y1="1.93934" x2="19.8796" y2="19.7583" stroke="#0959A1" stroke-width="3"/>
    <line x1="1.37879" y1="19.9393" x2="19.1977" y2="2.12039" stroke="#0959A1" stroke-width="3"/>
  </svg>

</template>

<script>
export default {
  name: 'IconClose',
};
</script>

<style lang="scss" module>
.icon {
  width: 18px;
  height: 18px;
}
</style>
