<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.24516 11.4267L5.60166 6.23745C5.92847 5.92085 6.45849 5.92085 6.78531 6.23745C7.11219 6.55413 7.11219 7.06747 6.78531 7.38414L2.85759 11.1892H17.163C17.6252 11.1892 18 11.5522 18 12C18 12.4477 17.6252 12.8108 17.163 12.8108H2.85759L6.78517 16.6158C7.11205 16.9325 7.11205 17.4459 6.78517 17.7625C6.6218 17.9207 6.40754 18 6.19335 18C5.97915 18 5.76496 17.9207 5.60152 17.7625L0.24516 12.5733C-0.08172 12.2567 -0.08172 11.7433 0.24516 11.4267Z" fill="#0959A1" />
  </svg>
</template>

<script>
export default {
  name: 'IconBack',
};
</script>

<style lang="scss" module></style>
